import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import ProjectPage from './ProjectPage';
import ProjectAtl from './ProjectAtl';
import AboutPage from './AboutPage'; // Import the AboutPage component

function App() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [active, setActive] = useState('work');

    return (
        <Router>
             <Routes>
                <Route path="/" element={<LandingPage active={active} setActive={setActive} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />} />
                <Route path="/project" element={<ProjectPage setActive={setActive} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />} />
                <Route path="/atl-airport" element={<ProjectAtl setActive={setActive} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />} />
                <Route path="/about" element={<AboutPage setActive={setActive} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />} />
            </Routes>
        </Router>
    );
}

export default App;

import { React, useEffect, useState, useRef } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Box, Container, Divider, Grid, ThemeProvider, Typography } from '@mui/material';
import NavigationMenu from './NavigationMenu';
import { createTheme } from '@mui/material/styles';
import './LandingPage.css';
import './ProjectMicro.css';
import './ProjectAtl.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { fontSize } from '@mui/system';
import 'animate.css';



{/* <FontAwesomeIcon icon="fa-solid fa-circle-chevron-right" /> */ }

// Reuse the theme from LandingPage for consistency
const theme = createTheme({
    typography: {
        fontSize: 16,
    },
});


const ProjectPage = ({ menuOpen, setMenuOpen, setActive }) => {
    const [isFloatingVisible, setIsFloatingVisible] = useState(false);
    const overviewRef = useRef(null);
    const navigate = useNavigate();
    const [userClicked, setUserClicked] = useState(false);


    const handleScroll = () => {
        if (overviewRef.current) {
            const rect = overviewRef.current.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
            setIsFloatingVisible(isVisible);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Call it to check the initial position
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleNavigation = (section) => {
        navigate(`/?section=${section}`);
    };


    // Directly set the active section on click without additional logic
    const handleSectionClick = (sectionId) => {
        setUserClicked(true); // Indicate that this change was triggered by a user click
        setActiveSection(sectionId);
        scrollToSection(sectionId);

        // Optionally, open the corresponding collapsible based on the clicked section
        const collapsibleSectionToOpen = mapContentSectionToSidebarSection(sectionId);
        if (collapsibleSectionToOpen) {
            setOpenSections({
                ...Object.keys(openSections).reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                }, {}),
                [collapsibleSectionToOpen]: true,
            });
        }
    };


    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    // State to control the visibility of each collapsible content
    const [openSections, setOpenSections] = useState({});

    // Function to toggle collapsible section
    const toggleSection = (sectionId) => {
        setOpenSections(prevState => ({
            ...prevState,
            [sectionId]: !prevState[sectionId]
        }));
    };

    // Ref for the content to measure its height for animation
    const contentRef = useRef({});

    const handleToggleAndScroll = (toggleSectionId, scrollSectionId) => {
        toggleSection(toggleSectionId); // Toggle the visibility of the section
        setTimeout(() => { // Set a timeout to wait for the toggle animation
            scrollToSection(scrollSectionId); // Scroll to the section after the toggle
        }, 0); // Adjust the timeout duration based on your toggle animation duration
    };

    const [activeSection, setActiveSection] = useState('');
    const sectionRefs = {
        'project-overview': useRef(null),
        'stakeholders': useRef(null),
        'timeline': useRef(null),
        'possible-solution': useRef(null),

        'research-methods': useRef(null),
        'persona': useRef(null),
        'journey-map': useRef(null),
        'key-findings': useRef(null),
        'user-needs': useRef(null),

        'metaphor': useRef(null),
        'affinity-diagram': useRef(null),
        'brainstorming': useRef(null),
        'design-concepts': useRef(null),
        'storyboards': useRef(null),
        'sketches': useRef(null),
        'wireframes': useRef(null),
        'prototype': useRef(null),


        // 'heuristic-evaluation': useRef(null),
        'usability-testing': useRef(null),

        'conclusion': useRef(null),



    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            let visibleSections = entries.filter(entry => entry.isIntersecting).map(entry => entry.target.id);

            if (visibleSections.length && !userClicked) { // Check if change is not due to user click
                let currentSection = visibleSections[0];
                setActiveSection(currentSection);

                // Logic to open only the corresponding collapsible section
                const collapsibleSectionToOpen = mapContentSectionToSidebarSection(currentSection);
                if (collapsibleSectionToOpen) {
                    setOpenSections(prevState => {
                        let newState = Object.keys(prevState).reduce((acc, key) => {
                            acc[key] = false; // Close all other sections
                            return acc;
                        }, {});
                        newState[collapsibleSectionToOpen] = true; // Open the current section
                        return newState;
                    });
                }
            } else {
                setUserClicked(false); // Reset userClicked back to false for automatic changes
            }
        }, { rootMargin: '0px', threshold: 0.5 });

        Object.values(sectionRefs).forEach(ref => {
            if (ref.current) observer.observe(ref.current);
        });

        return () => Object.values(sectionRefs).forEach(ref => {
            if (ref.current) observer.unobserve(ref.current);
        });
    }, [userClicked]); // Include userClicked in the dependency array



    // Function to map content section IDs to sidebar section keys
    function mapContentSectionToSidebarSection(contentSectionId) {
        // Example mapping, update according to your actual IDs and keys
        const mapping = {




            'research-methods': 'research',
            'persona': 'research',
            'journey-map': 'research',
            'key-findings': 'research',
            'user-needs': 'research',


            'metaphor': 'design',
            'affinity-diagram': 'design',
            'brainstorming': 'design',
            'design-concepts': 'design',
            'storyboards': 'design',
            'sketches': 'design',
            'wireframes': 'design',
            'prototype':'design',



            // 'heuristic-evaluation': 'evaluate',
            'usability-testing': 'evaluate',

            // 'wireframes': 'design',
            // 'iteration-feedback': 'design',
            // 'design-system': 'design',
            // 'prototype': 'design',

            // 'heuristic-evaluation': 'test',
            // 'usability': 'test',


            'conclusion': 'conclusion',
            // Add other mappings here
        };
        return mapping[contentSectionId] || contentSectionId;
    }


    return (
        <ThemeProvider theme={theme}>
            <Box className="landing-container project" sx={{ flexGrow: 1, position: 'relative' }}>


                <Container maxWidth={false} className="page-content" disableGutters >





                    {/* Your Project Page Content Here */}
                    <div className="project1-section1 background-atl">

                        <Container maxWidth={"lg"}  >
                            <NavigationMenu
                                active='project'
                                handleNavigation={handleNavigation}
                                menuOpen={menuOpen}
                                setMenuOpen={setMenuOpen}
                            />
                            <div className="slide1 slide2">
                                <Grid container spacing={2}>
                                    {/* Left column with placeholder image */}
                                    <Grid item xs={6}>
                                        <img
                                            src={require('../src/assets/project2/casestudyatl.png')}
                                            alt="Placeholder"
                                            className='project-image'
                                            onAnimationEnd={() => {

                                            }}
                                        />


                                    </Grid>
                                    {/* Right column with two titles */}
                                    <Grid item xs={6} >
                                        <div className="title-container animate__animated animate__fadeIn animate__delay-900ms">
                                            <div className="title-bg title-bg-atl">
                                                <Typography variant="h5">
                                                    UX Case Study
                                                </Typography>
                                            </div>
                                            <Typography variant="h5">
                                                Enhancing Airport Accessibility for People with Reduced Mobility
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>

                            </div>
                            <Container style={{ maxWidth: '980px' }}  >
                                <Divider style={{ marginBottom: '10px' }} />
                                <div className="animate__animated animate__fadeIn animate__delay-900ms">
                                    <div className='info-case-grid' >
                                        {/* <Grid item>
                                        <h1 className="item-text item-text-atl" style={{ width: '100%' }}>
                                            Role
                                        </h1>
                                        <p style={{ width: '100%' }}>
                                            Lead Designer
                                        </p>
                                        <p style={{ width: '100%' }}>
                                            User Researcher
                                        </p>
                                    </Grid> */}
                                        <div>
                                            {/* Column 2 */}
                                            <h1 className="item-text item-text-atl">
                                                Project Context
                                            </h1>

                                            <p>
                                                HCI Graduate Course
                                            </p>
                                            <p>
                                                Aug - Dec 2023
                                            </p>
                                            <p>
                                                UX Designer, User researcher
                                            </p>
                                        </div>
                                        <div >
                                            {/* Column 3 */}
                                            <h1 className="item-text item-text-atl">
                                                Team
                                            </h1>
                                            <p >
                                                Amin El Asery, Debbie Hsu
                                            </p>
                                            <p >
                                                Nia Moore, Kira Dubose
                                            </p>
                                            {/* <div className='team-grid'>
                                            <div style={{ paddingRight: '1rem' }}>
                                                <p style={{ fontWeight: '600' }} >
                                                    Georgia Tech Team

                                                </p>
                                                <p style={{ fontSize: '13px', transform: 'translateY(-0.6rem)' }}>
                                                    2 UX Designers / 2 UX Researchers
                                                </p>
                                            </div>
                                            <div>
                                                <p style={{ fontWeight: '600', transform: 'translateY(-0.7rem)' }}>
                                                    Microsoft Team
                                                </p>

                                                <p style={{ fontSize: '13px', transform: 'translateY(-1.3rem)' }}>
                                                    2 UX Designers / 1 UX Researcher / 1 PM
                                                </p>
                                            </div>
                                        </div> */}





                                        </div>
                                        <div>
                                            {/* Column 5 */}
                                            <h1 className="item-text item-text-atl" >
                                                Tools
                                            </h1>
                                            <p >
                                                Figma, Miro
                                            </p>
                                            <p >
                                                Qualtrics, Jira                                   </p>

                                        </div>

                                        {/* <div>
                                        <h1 className="item-text item-text-atl" >
                                            Skills
                                        </h1>
                                        <p >
                                            UX Interaction,
                                        </p>
                                        <p >
                                            Rapid Prototype </p>
                                        <p >
                                            Rapid Prototype </p>

                                    </div> */}
                                        {/* <div>
                                        <h1 className="item-text item-text-atl">
                                            Skills
                                        </h1>
                                        <p >
                                            User Interview, Survey, Product Walkthrough Competitive Analysis, Task Analysis, Empathy Map, Feature Matrix, Affinity Diagram, sketch low-fi concepts, wire-frame, high fi, heuristic evaluation, usability test
                                        </p>

                                    </div> */}



                                    </div>
                                    <div className='process process-atl'>
                                        {/* Column 5 */}
                                        <h1 className="item-text item-text-atl" >
                                            Process
                                        </h1>
                                        <p >
                                            Literature Review, Ethnographic Observations, Stakeholder Interviews, User Interview, Task Analysis, Empathy Map, Persona, User Journey Map, Affinity Diagram, Design Concepts, Storyboards, Sketch, Low-Fi Concepts, Wireframes, High Fi, Heuristic Evaluation, Usability Test                                    </p>



                                    </div>
                                    <div>
                                        {/* Column 5 */}
                                        <h1 className="item-text item-text-atl" >
                                            Role
                                        </h1>
                                        <div className='role-uxdr'>
                                            <p >
                                                <span style={{ fontWeight: 700 }}>Research Phase:</span> I conducted literature reviews, performed ethnographic observations, created task analyses, led the development of the affinity diagram, developed empathy maps, facilitated user interviews for deeper insights, and participated in all research activities.
                                            </p>
                                            <p >
                                                <span style={{ fontWeight: 700 }}>Design Phase:</span> I participated in ideation, concept creation, sketched designs, created storyboard scripts, and developed a design system. I also crafted wireframes and UI designs, facilitating four user feedback sessions. In the final phase, I produced a high-fidelity prototype, and led 3 user testing sessions.
                                            </p>
                                        </div>


                                    </div>
                                </div>

                                {/* <h1 className="item-text item-text-atl" style={{ width: '100%' }}>
                                    Tools
                                </h1>
                                <p style={{ width: '100%' }}>
                                    Figma
                                </p> */}
                                {/* <p className="project-paragraph">
                                As the lead designer on the team, I was responsible for all design and prototyping tasks.

Research Phase: I led surveys and interviews to gather user insights.

Design Phase: I worked on ideation, developed wireframes, crafted the design system, and led six user feedback sessions.

Final Design and Evaluation Phase: I created high-fidelity prototypes, led two heuristic evaluations, and conducted three user testing sessions
                                </p> */}
                            </Container>


                        </Container>
                    </div>
                    <div className="content-wrapper content-wrapper-atl">

                        {isFloatingVisible && (
                            <div
                                className="floating-container floating-container-atl"
                                style={{
                                    position: 'fixed',
                                    top: '50%',
                                    left: '46%',
                                    transform: 'translate(-345%, -65%)',
                                    zIndex: 1000,
                                    // Additional styles for the floating container
                                }}
                            >
                                {/* Floating container content */}

                                <div className="index-title index-title-atl">
                                    <p>Quick read:</p>
                                </div>

                                {/* <div className={`custom-bullet custom-bullet-atl index ${activeSection === 'conclusion' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('conclusion')}>
                                    <p>Conclusion</p>
                                </div> */}


                                <div className={`custom-bullet  custom-bullet-atl index  ${activeSection === 'project-overview' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('project-overview')}>
                                    <p>Project overview</p>
                                </div>
                                <div className={`custom-bullet custom-bullet-atl index  ${activeSection === 'stakeholders' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('stakeholders')}>
                                    <p>Stakeholders</p>
                                </div>

                                <div className={`custom-bullet custom-bullet-atl index  ${activeSection === 'timeline' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('timeline')}>
                                    <p>Timeline</p>
                                </div>

                                <div className={`custom-bullet custom-bullet-atl index  ${activeSection === 'possible-solution' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('possible-solution')}>
                                    <p>Project Outcome</p>
                                </div>

                                <div className="index-title index-title-atl">
                                    <p style={{ transform: "translateY(0.5rem)" }}>Delve in:</p>
                                </div>




                                <div>
                                    <div onClick={() => handleToggleAndScroll('research', 'research')} className="collapsible-header">
                                        <div className="custom-bullet custom-bullet-atl index indextitlecolla " >
                                            <p>Research</p>
                                        </div>
                                        <div className="icon-circle-background">
                                            {/* <FontAwesomeIcon icon={openSections['research'] ? faChevronDown : faChevronRight} className="fa-icon" /> */}
                                            <FontAwesomeIcon icon={faChevronRight} className={`fa-icon ${openSections['research'] ? 'icon-rotate open' : 'icon-rotate'}`} />
                                        </div>
                                    </div>


                                    <div
                                        ref={el => contentRef.current['research'] = el}
                                        style={{
                                            color: 'red',
                                            maxHeight: openSections['research'] ? `${contentRef.current['research']?.scrollHeight}px` : '0',
                                            transition: 'max-height 0.5s ease-in-out',
                                            overflow: 'hidden',
                                        }}
                                        className="collapsible-content"
                                    >


                                        {/* <div
                                            id="survey"
                                            ref={sectionRefs['survey']}
                                            className={activeSection === 'survey' ? 'active-class active-class-atl' : ''}
                                        > */}
                                        <div ref={sectionRefs['research-methods']} className={`custom-bullet index subindex ${activeSection === 'research-methods' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('research-methods')}>

                                            <p>Research Methods</p>
                                        </div>
                                        <div ref={sectionRefs['key-findings']} className={`custom-bullet index subindex ${activeSection === 'key-findings' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('key-findings')}>
                                            <p>Key Findings</p>
                                        </div>

                                        <div ref={sectionRefs['persona']} className={`custom-bullet index subindex ${activeSection === 'persona' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('persona')}>
                                            <p>Persona</p>
                                        </div>

                                        <div ref={sectionRefs['journey-map']} className={`custom-bullet index subindex ${activeSection === 'journey-map' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('journey-map')}>
                                            <p>Journey Map</p>
                                        </div>

                                        <div ref={sectionRefs['user-needs']} className={`custom-bullet index subindex ${activeSection === 'user-needs' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('user-needs')}>
                                            <p>User Needs</p>
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <div onClick={() => handleToggleAndScroll('design', 'design')} className="collapsible-header">
                                        <div className="custom-bullet custom-bullet-atl index indextitlecolla ">
                                            <p>Design</p>
                                        </div>
                                        <div className="icon-circle-background">
                                            {/* <FontAwesomeIcon icon={openSections['research'] ? faChevronDown : faChevronRight} className="fa-icon" /> */}
                                            <FontAwesomeIcon icon={faChevronRight} className={`fa-icon ${openSections['design'] ? 'icon-rotate open' : 'icon-rotate'}`} />
                                        </div>
                                    </div>


                                    <div
                                        ref={el => contentRef.current['design'] = el}
                                        style={{
                                            color: 'red',
                                            maxHeight: openSections['design'] ? `${contentRef.current['design']?.scrollHeight}px` : '0',
                                            transition: 'max-height 0.5s ease-in-out',
                                            overflow: 'hidden',
                                        }}
                                        className="collapsible-content"
                                    >

                                        <div ref={sectionRefs['metaphor']} className={`custom-bullet index subindex ${activeSection === 'metaphor' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('metaphor')}>
                                            <p>Ideation</p>
                                        </div>
                                        <div ref={sectionRefs['affinity-diagram']} className={`custom-bullet index subindex ${activeSection === 'affinity-diagram' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('affinity-diagram')}>
                                            <p>Affinity diagram</p>
                                        </div>
                                        <div ref={sectionRefs['brainstorming']} className={`custom-bullet index subindex ${activeSection === 'brainstorming' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('brainstorming')}>
                                            <p>Brainstorming</p>
                                        </div>
                                        <div ref={sectionRefs['design-concepts']} className={`custom-bullet index subindex ${activeSection === 'design-concepts' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('design-concepts')}>
                                            <p>Design concepts</p>
                                        </div>
                                        <div ref={sectionRefs['storyboards']} className={`custom-bullet index subindex ${activeSection === 'storyboards' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('storyboards')}>
                                            <p>Storyboards</p>
                                        </div>
                                        <div ref={sectionRefs['sketches']} className={`custom-bullet index subindex ${activeSection === 'sketches' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('sketches')}>
                                            <p>Sketches</p>
                                        </div>
                                        <div ref={sectionRefs['wireframes']} className={`custom-bullet index subindex ${activeSection === 'wireframes' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('wireframes')}>
                                            <p>Wireframes</p>
                                        </div>
                                        <div ref={sectionRefs['prototype']} className={`custom-bullet index subindex ${activeSection === 'prototype' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('prototype')}>
                                            <p>Prototype</p>
                                        </div>
                                    </div>

                                </div>


                                <div>
                                    <div onClick={() => handleToggleAndScroll('evaluate', 'evaluate')} className="collapsible-header">
                                        <div className="custom-bullet custom-bullet-atl  index indextitlecolla ">
                                            <p>Evaluate</p>
                                        </div>
                                        <div className="icon-circle-background">
                                            {/* <FontAwesomeIcon icon={openSections['research'] ? faChevronDown : faChevronRight} className="fa-icon" /> */}
                                            <FontAwesomeIcon icon={faChevronRight} className={`fa-icon ${openSections['evaluate'] ? 'icon-rotate open' : 'icon-rotate'}`} />
                                        </div>
                                    </div>


                                    <div
                                        ref={el => contentRef.current['evaluate'] = el}
                                        style={{
                                            color: 'red',
                                            maxHeight: openSections['evaluate'] ? `${contentRef.current['evaluate']?.scrollHeight}px` : '0',
                                            transition: 'max-height 0.5s ease-in-out',
                                            overflow: 'hidden',
                                        }}
                                        className="collapsible-content"
                                    >

                                        <div className={`custom-bullet index subindex ${activeSection === 'usability-testing' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('fusability-testing')}>
                                            <p>Usability testing</p>
                                        </div>
                                       
                                    </div>

                                </div>

                                {/* <div>
                                    <div onClick={() => handleToggleAndScroll('design', 'design')} className="collapsible-header">
                                        <div className="custom-bullet index indextitlecolla ">
                                            <p>Design</p>
                                        </div>
                                        <div className="icon-circle-background">
                                            <FontAwesomeIcon icon={faChevronRight} className={`fa-icon ${openSections['design'] ? 'icon-rotate open' : 'icon-rotate'}`} />
                                        </div>
                                    </div>


                                    <div
                                        ref={el => contentRef.current['design'] = el}
                                        style={{
                                            color: 'red',
                                            maxHeight: openSections['design'] ? `${contentRef.current['design']?.scrollHeight}px` : '0',
                                            transition: 'max-height 0.5s ease-in-out',
                                            overflow: 'hidden',
                                        }}
                                        className="collapsible-content"
                                    >

                                        <div className={`custom-bullet index subindex ${activeSection === 'wireframes' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('wireframes')}>
                                            <p>Wireframes</p>
                                        </div>
                                        <div className={`custom-bullet index subindex ${activeSection === 'iteration-feedback' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('iteration-feedback')}>
                                            <p>Iteration feedback</p>
                                        </div>
                                        <div className={`custom-bullet index subindex ${activeSection === 'design-system' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('design-system')}>
                                            <p>Design system</p>
                                        </div>
                                        <div className={`custom-bullet index subindex ${activeSection === 'prototype' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('prototype')}>
                                            <p>Prototype</p>
                                        </div>
                                    </div>

                                </div> */}


                                {/* <div>
                                    <div onClick={() => handleToggleAndScroll('test', 'test')} className="collapsible-header">
                                        <div className="custom-bullet index indextitlecolla ">
                                            <p>Test</p>
                                        </div>
                                        <div className="icon-circle-background">
                                            <FontAwesomeIcon icon={faChevronRight} className={`fa-icon ${openSections['test'] ? 'icon-rotate open' : 'icon-rotate'}`} />
                                        </div>
                                    </div>


                                    <div
                                        ref={el => contentRef.current['test'] = el}
                                        style={{
                                            color: 'red',
                                            maxHeight: openSections['test'] ? `${contentRef.current['test']?.scrollHeight}px` : '0',
                                            transition: 'max-height 0.5s ease-in-out',
                                            overflow: 'hidden',
                                        }}
                                        className="collapsible-content"
                                    >

                                        <div className={`custom-bullet index subindex ${activeSection === 'heuristic-evaluation' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('heuristic-evaluation')}>
                                            <p>Heuristic evaluation</p>
                                        </div>
                                        <div className={`custom-bullet index subindex ${activeSection === 'usability' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('usability')}>
                                            <p>Usability testing</p>
                                        </div>
                                    </div>

                                </div> */}

                                <div className={`custom-bullet custom-bullet-atl index ${activeSection === 'conclusion' ? 'active-class active-class-atl' : ''}`} onClick={() => handleSectionClick('conclusion')}>
                                    <p>Conclusion</p>
                                </div>
                            </div>
                        )}

                        <div ref={overviewRef} className="project-overview-content">

                            <div className="project1-section2">
                                {/* <Container style={{ maxWidth: '1100px' }} > */}
                                <Container style={{ maxWidth: '980px' }} >
                                    <div className="first-frame">
                                        <div className="center-title">
                                            <div className="red-background orange-bg">


                                                <h1 id="project-overview" ref={sectionRefs['project-overview']} className="full-text" >Project <span className="red-text red-text-atalnta">Overview</span></h1>
                                            </div>
                                        </div>
                                        <p className="project-paragraph">

                                            People with reduced mobility (PRM) need to be able to navigate airports more easily. Many PRMs struggle to get timely assistance while traveling and rely heavily on travel companions for help. While airlines and airports have been trying to improve their accessible services, they often seem to focus more on their own convenience than on prioritizing PRMs' needs. This lack of a comprehensive support system for PRMs can negatively impact their travel experiences and discourage them from traveling again.
                                        </p>
                                    </div>

                                    <div className="first-frame">
                                        <div className="center-title">
                                            <div className="red-background orange-bg">
                                                <h1 id="stakeholders" ref={sectionRefs['stakeholders']} className="full-text" >Users and <span className="red-text red-text-atalnta">Stakeholders</span></h1>
                                            </div>
                                        </div>
                                        <div className='stakeholder-atl'>
                                            <p className="project-paragraph">
                                                The stakeholder map highlights the layered relationships that shape the airport experience for People with Reduced Mobility (PRMs). PRMs are at the center, directly engaging with traveling partners and accessibility staff who provide immediate support and with TSA staff for security measures. The airlines' role, while indirect, is pivotal as they hire and train the staff that assist PRMs. Airports create the infrastructure and environment where these interactions occur. The Federal Aviation Administration (FAA) sets regulatory standards that influence all levels of operations, from staff training to the enforcement of policies by law enforcement, which indirectly affects the PRM's journey.
                                            </p>
                                            <div className='stake-img'>
                                                <img
                                                    src={require('../src/assets/project2/stake-atl2.jpg')}
                                                    alt="Placeholder"
                                                    className="small-icon-image"
                                                />
                                            </div>



                                        </div>

                                    </div>

                                    <div className="first-frame">
                                        <div className="center-title">
                                            <div className="red-background orange-bg">
                                                <h1 id="timeline" ref={sectionRefs['timeline']} className="full-text" ><span className="red-text red-text-atalnta">Timeline</span></h1>
                                            </div>
                                        </div>
                                        <div className='timeline'>
                                            <img
                                                src={require('../src/assets/project2/timeline.png')}
                                                alt="Placeholder"
                                                className="small-icon-image"
                                            />
                                        </div>

                                    </div>

                                    <div className="first-frame">
                                        <div className="center-title">
                                            <div className="red-background orange-bg">
                                                <h1 id="possible-solution" ref={sectionRefs['possible-solution']} className="full-text" >Project<span className="red-text red-text-atalnta"> Outcome</span></h1>
                                            </div>
                                        </div>
                                        <p className="project-paragraph">
                                            The outcome of this project consists of four reports, a high fidelity prototype, along with procedural findings, information findings, user research findings and recommendations.                                            </p>

                                        <div className='entire-thing'>
                                            <div className='solution-grid'>
                                                <div>
                                                    <div>
                                                        <img
                                                            src={require('../src/assets/project2/mainatl.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />

                                                    </div>

                                                </div>

                                                <div>
                                                    <div>
                                                        <img
                                                            src={require('../src/assets/project2/screening.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />

                                                    </div>

                                                </div>

                                                <div>
                                                    <div>
                                                        <img
                                                            src={require('../src/assets/project2/armap.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />

                                                    </div>

                                                </div>

                                                <div>
                                                    <div>
                                                        <video
                                                            autoPlay
                                                            muted
                                                            loop
                                                            src={require('../src/assets/project2/videoaiassitant.mp4')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />

                                                    </div>
                                                </div>

                                                <div>
                                                    <div>
                                                        <img
                                                            src={require('../src/assets/project2/post-flight.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />

                                                    </div>


                                                </div>

                                            </div>

                                            <div className='solution-grid-para'>
                                                <div className='solution-number'>
                                                    <div className="custom-bullet-point  bullet-atl-finding">
                                                        <div className="finding-bullet finding-bullet-atl solution-bullet ">1</div>
                                                    </div>
                                                    <p className="project-paragraph caption caption-solu">
                                                        The main screen for entering travel details and accessing a digital companion for all flight phases, plus help and "I have arrived" options for airport staff alerts.                                                 </p>

                                                </div>

                                                <div className='solution-number'>
                                                    <div className="custom-bullet-point  bullet-atl-finding">
                                                        <div className="finding-bullet finding-bullet-atl solution-bullet ">2</div>
                                                    </div>
                                                    <p className="project-paragraph caption caption-solu">
                                                        A pre-flight screening interface that queries PRMs about their specific needs to ensure they are properly accommodated upon arrival at the airport.
                                                    </p>
                                                </div>

                                                <div className='solution-number'>
                                                    <div className="custom-bullet-point  bullet-atl-finding">
                                                        <div className="finding-bullet finding-bullet-atl solution-bullet ">3</div>
                                                    </div>
                                                    <p className="project-paragraph caption caption-solu">
                                                        A pre-boarding augmented reality screen guiding PRMs through the airport to their gate, displaying estimated times for check-in, security checks, and gate departure.
                                                    </p>
                                                </div>


                                                <div className='solution-number'>
                                                    <div className="custom-bullet-point  bullet-atl-finding">
                                                        <div className="finding-bullet finding-bullet-atl solution-bullet ">4</div>
                                                    </div>
                                                    <p className="project-paragraph caption caption-solu">
                                                        An AI assistant, accessed via a help button, allows voice or text communication, and includes a feature to contact airport staff for personalized help.                                                </p>
                                                </div>



                                                <div className='solution-number'>
                                                    <div className="custom-bullet-point  bullet-atl-finding">
                                                        <div className="finding-bullet finding-bullet-atl solution-bullet ">5</div>
                                                    </div>
                                                    <p className="project-paragraph caption caption-solu">
                                                        A post-flight feedback screen where users can submit their experience feedback, providing valuable insights to airport staff for potential service improvements.
                                                    </p>
                                                </div>

                                            </div>

                                        </div>


                                    </div>


                                    <div >
                                        <div>
                                            <div className="center-title">
                                                <div className="red-background orange-bg ">
                                                    <h1 id="research" className="full-text red-text-atalnta third-frame-main" >Research <span className="red-text third-frame">Process</span></h1>
                                                </div>
                                            </div>

                                            <p className="project-paragraph">

                                                Our research process involved various methodologies to gain a comprehensive understanding of the problem space:                                                </p>
                                            <div className="grid-container-context problemspace">
                                                <div >
                                                    {/* <div className="red-background orange-bg context">
                                                        <h1 ><span className="red-text-atalnta">Literature Review</span></h1>
                                                    </div> */}
                                                    {/* <h1 className="research-method-title">Literature Review</h1> */}

                                                    <div className="research-method-title">
                                                        <p>Literature</p>
                                                        <p>Review</p>
                                                    </div>
                                                    <div className="problem">
                                                        <p id="research-methods" ref={sectionRefs['research-methods']} className="project-paragraph">
                                                            I reviewed existing research on airport accessibility challenges faced by PRMs and strategies employed by airports/airlines.                                                        </p>
                                                    </div>
                                                </div>
                                                <div>


                                                    <div className="research-method-title">
                                                        <p>Ethnographic</p>
                                                        <p>Observations</p>
                                                    </div>
                                                    <div className="problem">
                                                        <p className="project-paragraph">
                                                            I observed PRMs navigating airports, directly noting their experiences and the specific challenges they face during travel.                                                                                              </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    {/* <h1 className="research-method-title" >Stakeholder Interviews</h1> */}

                                                    <div className="research-method-title">
                                                        <p>Stakeholder</p>
                                                        <p>Interviews</p>
                                                    </div>
                                                    <div className="problem">
                                                        <p className="project-paragraph">
                                                            We conducted interviews with Delta Airlines employees to gain insight into the aviation industry’s stance on accessibility.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    {/* <div className="red-background orange-bg context">
                                                        <h1><span className="red-text-atalnta">User Interviews</span></h1>
                                                    </div> */}

                                                    <div className="research-method-title">
                                                        <p>User</p>
                                                        <p>Interviews</p>
                                                    </div>
                                                    <div className="problem">
                                                        <p className="project-paragraph">
                                                            We interviewed four PRMs, collecting rich qualitative data about their distinct needs, frustrations, and adaptive strategies.                                                                                                                                                         </p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="caption-images">
                                                <img
                                                    src={require('../src/assets/project2/album.png')}
                                                    alt="Placeholder"
                                                    className="small-icon-image"
                                                />
                                                <p className="project-paragraph caption-storyboard">
                                                    Photos I took during the observation activity at Atlanta Airport of accessibility services offered
                                                </p>
                                            </div>

                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="key-findings" ref={sectionRefs['key-findings']} className="method">Key Findings</h1>
                                            </div>
                                            <p className="project-paragraph">
                                                Our research revealed several critical issues faced by PRMs:
                                            </p>
                                            <div className='findings-grid-atl'>
                                                <div>
                                                    <div>
                                                        <div className="custom-bullet-point finding bullet-atl-finding">
                                                            {/* <div className="custom-bullet bullet-finding"></div> */}
                                                            <div className="finding-bullet finding-bullet-atl">1</div>
                                                            <p>Family Dependence</p>

                                                        </div>
                                                        <p className="small-finding small-finding-atl">
                                                            PRMs heavily rely on family members or travel companions for assistance throughout their airport journey.

                                                        </p>
                                                    </div>
                                                    <div>
                                                        <div className="custom-bullet-point finding bullet-atl-finding">
                                                            {/* <div className="custom-bullet bullet-finding"></div> */}
                                                            <div className="finding-bullet finding-bullet-atl ">2</div>
                                                            <p>Staff Support Inconsistencies</p>

                                                        </div>
                                                        <p className="small-finding small-finding-atl">
                                                            Experiences with airport staff varied greatly, with some being helpful while others were not. Support also varied across different stages of travel.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <div className="custom-bullet-point finding bullet-atl-finding">
                                                            {/* <div className="custom-bullet bullet-finding"></div> */}
                                                            <div className="finding-bullet finding-bullet-atl">3</div>
                                                            <p>Lack of Communication </p>

                                                        </div>
                                                        <p className="small-finding small-finding-atl">
                                                            There was a lack of clear upfront communication from airlines and airports about accessibility features and procedures.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <div className="custom-bullet-point finding bullet-atl-finding">
                                                            {/* <div className="custom-bullet bullet-finding"></div> */}
                                                            <div className="finding-bullet finding-bullet-atl">4</div>
                                                            <p>Assistance Delays </p>

                                                        </div>
                                                        <p className="small-finding small-finding-atl">
                                                            PRMs struggled to receive timely assistance, especially during transitions like going between gates or waiting in long lines.
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <div className="custom-bullet-point finding bullet-atl-finding">
                                                            {/* <div className="custom-bullet bullet-finding"></div> */}
                                                            <div className="finding-bullet finding-bullet-atl">5</div>
                                                            <p>Emotional Burden </p>

                                                        </div>
                                                        <p className="small-finding small-finding-atl">
                                                            The cumulative challenges of air travel took an emotional toll, with many PRMs feeling like a burden.                                                        </p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div>
                                                        <div className="custom-bullet-point finding bullet-atl-finding">
                                                            {/* <div className="custom-bullet bullet-finding"></div> */}
                                                            <div className="finding-bullet finding-bullet-atl">6</div>
                                                            <p>Screening Discomfort </p>

                                                        </div>
                                                        <p className="small-finding small-finding-atl">
                                                            Going through security screenings was an uncomfortable and invasive experience for many PRMs.                                                                                                                 </p>
                                                    </div>
                                                    <div>
                                                        <div className="custom-bullet-point finding bullet-atl-finding">
                                                            {/* <div className="custom-bullet bullet-finding"></div> */}
                                                            <div className="finding-bullet finding-bullet-atl">7</div>
                                                            <p>Accessibility Deprioritized </p>

                                                        </div>
                                                        <p className="small-finding small-finding-atl">
                                                            Airport infrastructure and services often neglected accessibility needs in favor of able-bodied passengers.                                                                                                               </p>
                                                    </div>
                                                    <div>
                                                        <div className="custom-bullet-point finding bullet-atl-finding">
                                                            {/* <div className="custom-bullet bullet-finding"></div> */}
                                                            <div className="finding-bullet finding-bullet-atl">8</div>
                                                            <p>Strategic Travel Adaptations </p>

                                                        </div>
                                                        <p className="small-finding small-finding-atl">
                                                            To enhance comfort and independence, PRMs made specific choices like purchasing first-class tickets or using preferred mobility devices.                                                        </p>
                                                    </div>
                                                    <div>
                                                        <div className="custom-bullet-point finding bullet-atl-finding">
                                                            {/* <div className="custom-bullet bullet-finding"></div> */}
                                                            <div className="finding-bullet finding-bullet-atl">9</div>
                                                            <p>Mobility Aid Reliance </p>

                                                        </div>
                                                        <p className="small-finding small-finding-atl">
                                                            PRMs expressed reliance on airport-provided wheelchairs and staff assistance due to their distinct mobility needs.                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                            {/* <div className="grid-container involved">
                                                <div className="first-frame">
                                                    <div className="users">
                                                        <img
                                                            src={require('../src/assets/project1/users.png')}
                                                            alt="Placeholder"
                                                            className="small-icon-image"
                                                        />
                                                    </div>
                                                    <div className="center-title context-main">
                                                        <div className="red-background orange-bg context">
                                                            <h1 className="full-text-context" ><span className="red-text">Stakeholders</span></h1>
                                                        </div>
                                                    </div>


                                                    <div className="project-paragraph custom stakeholders rightholder contextOfUse">
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Business owners</p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Community Admins </p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Customer Services</p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Marketing Team</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="first-frame">
                                                    <div className="users">
                                                        <img
                                                            src={require('../src/assets/project1/target.png')}
                                                            alt="Placeholder"
                                                            className="small-icon-image"
                                                        />
                                                    </div>
                                                    <div className="center-title context-main">
                                                        <div className="red-background orange-bg context">
                                                            <h1 className="full-text-context" ><span className="red-text">Target User</span></h1>
                                                        </div>
                                                    </div>

                                                    <div className="project-paragraph custom stakeholders contextOfUse">
                                                        <img src={require('../src/assets/project1/focus.png')} alt="Overlay Image" style={{ position: 'absolute', top: '-78px', left: '-24px', zIndex: '10' }} />
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Regular customers looking for new ways to interact with businesses who are using Teams for Life (free version)</p>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div> */}
                                        </div>


                                        {/* <div className="first-frame">
                                            <div className="center-title">
                                                <div className="red-background orange-bg">
                                                    <h1 id="problem-statement" ref={sectionRefs['problem-statement']} className="full-text third-frame-main" >Problem <span className="red-text third-frame">Statement</span></h1>
                                                </div>
                                            </div>
                                            <p className="project-paragraph">
                                                Businesses lack ways to communicate with their customers effectively via Microsoft Teams. Such communication is essential for businesses in times of marketing, product launches, feedback gathering, community building, and customer service. Without a way for businesses and customers to communicate effortlessly, businesses will likely lose valuable information about their users, and customers will also miss important updates from their service providers.
                                            </p>
                                        </div>

                                        <div className="first-frame">
                                            <div className="placeholder-image-column hmw">
                                                <img
                                                    src={require('../src/assets/project1/hmw.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                        </div> */}

                                        <div className="first-frame">
                                            {/* <div className="center-title">
                                                <div className="red-background orange-bg">
                                                    <h1 id="possible-solution" ref={sectionRefs['possible-solution']} className="full-text" >Key <span className="red-text">Findings</span></h1>
                                                </div>
                                            </div> */}

                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="persona" ref={sectionRefs['persona']} className="method">Persona</h1>
                                            </div>

                                            <div className="artifact-grid">
                                                <p className="project-paragraph">
                                                    Personas and empathy map detailed diverse experiences of PRMs at airports, highlighting specific needs, challenges, and interactions. These narratives illuminated the realities faced by PRMs, emphasizing the impact of airport design and service on their travel experience. Artifacts guided the design process, focusing on user-centric features, pinpointing exact pain points, and identifying goals to enhance PRMs' journeys                                                                                                                       </p>
                                                <div className='bg-frame-persona'>
                                                    <iframe className="miro-frame-persona" width="280" height="280" src="https://miro.com/app/embed/uXjVKbijSyY=/?pres=1&frameId=3458764583256158785&embedId=626421286871&autoplay=yep" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe>

                                                </div>
                                            </div>


                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="journey-map" ref={sectionRefs['journey-map']} className="method">Experience Journey Map</h1>
                                            </div>

                                            <p className="project-paragraph">
                                                Compiling insights from observational studies and in-depth interviews, a journey map for PRMs navigating airports was developed. It outlines the sequential steps PRMs typically follow from pre-travel to post-flight. This map encapsulates the key activities identified during PRMs' airport experiences, highlighting their interactions with airport facilities and staff.                                                                           </p>
                                            <div className='bg-frame'>
                                                <iframe className="miro-frame" width="932px" height="932px" src="https://miro.com/app/embed/uXjVKdJFzkI=/?pres=1&frameId=3458764583256158785&embedId=626421286871&autoplay=yep" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe>

                                            </div>

                                            {/* <div className="artifact-grid">
                                                <p className="project-paragraph">
                                                Compiling all the findings we gained from the informal interview and contextual inquery, I created a brand manager’s experience journey map. We assume the person is conducting research in the following steps: prepare, conduct, document and store research, share and use research. This incorporates all of the main tasks we found users at Focus Brands perform while they conduct research.                                                </p>
                                                <div className='bg-frame'>
                                                    <iframe className="miro-frame" width="332" height="332" src="https://miro.com/app/embed/uXjVKdJFzkI=/?pres=1&frameId=3458764583256158785&embedId=626421286871&autoplay=yep" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe>

                                                </div>
                                            </div> */}




                                            {/* <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 className="method">Task Analysis</h1>
                                            </div>
                                            <p className="project-paragraph">
                                                Add task analysis here
                                            </p>
                                            <iframe width="100%" height="432" style={{ borderRadius: "30px" }} src="https://miro.com/app/live-embed/uXjVNl6Wh8A=/?autoplay=true&moveToViewport=-1240,-1303,1928,178" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe>
                                             */}

                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 className="method">User Needs and Design Implications</h1>
                                            </div>
                                            {/* <p className="project-paragraph">
                                                Add task analysis here
                                            </p> */}
                                            <div className='user-needs-atl'>
                                                <div className="custom-bullet-point finding bullet-atl-finding">
                                                    <div className="finding-bullet finding-bullet-atl user-need-bullet">UN 1:</div>
                                                    <p>PRMs need to move freely through airports with less reliance on others</p>
                                                </div>
                                                <p className="small-finding-atl user-need-para">
                                                    <span style={{ color: "#d2953f", fontWeight: "700" }}>DI: </span> Increase PRMs self-reliance for navigation around the airport                                                                                                                </p>
                                            </div>
                                            <div className='user-needs-atl'>
                                                <div className="custom-bullet-point finding bullet-atl-finding">
                                                    <div className="finding-bullet finding-bullet-atl user-need-bullet">UN 2:</div>
                                                    <p>PRMs require quick, clear methods to request and receive assistance

                                                    </p>
                                                </div>
                                                <p className="small-finding-atl user-need-para">
                                                    <span style={{ color: "#d2953f", fontWeight: "700" }}>DI: </span>Allowing users to communicate their needs without barrier and in a timely manner                                                                                                                 </p>
                                            </div>
                                            <div className='user-needs-atl'>
                                                <div className="custom-bullet-point finding bullet-atl-finding">
                                                    <div className="finding-bullet finding-bullet-atl user-need-bullet">UN 3:</div>
                                                    <p>PRMs require complimentary, prioritized access to essential services</p>
                                                </div>
                                                <p className="small-finding-atl user-need-para">
                                                    <span style={{ color: "#d2953f", fontWeight: "700" }}>DI: </span>The system should be free of charge                                                                                                                </p>
                                            </div>
                                            <div className='user-needs-atl'>
                                                <div className="custom-bullet-point finding bullet-atl-finding">
                                                    <div className="finding-bullet finding-bullet-atl user-need-bullet">UN 4:</div>
                                                    <p>PRMs need a straightforward way to request assistance without advance notice.</p>
                                                </div>
                                                <p className="small-finding-atl user-need-para">
                                                    <span style={{ color: "#d2953f", fontWeight: "700" }}>DI: </span>The system should be easily accessible and require minimal preparation by stakeholders                                                                                                                </p>
                                            </div>
                                            <div className='user-needs-atl'>
                                                <div className="custom-bullet-point finding bullet-atl-finding">
                                                    <div className="finding-bullet finding-bullet-atl user-need-bullet">UN 5:</div>
                                                    <p>PRMs need accessible, varied channels to submit feedback on airport services</p>
                                                </div>
                                                <p className="small-finding-atl user-need-para">
                                                    <span style={{ color: "#d2953f", fontWeight: "700" }}>DI: </span>PRMs should be able to provide feedback and report inappropriate instances                                                                                                       </p>
                                            </div>
                                            <div className='user-needs-atl'>
                                                <div className="custom-bullet-point finding bullet-atl-finding">
                                                    <div className="finding-bullet finding-bullet-atl user-need-bullet">UN 6:</div>
                                                    <p>PRMs need a universally accessible system adaptable to various mobility conditions</p>
                                                </div>
                                                <p className="small-finding-atl user-need-para">
                                                    <span style={{ color: "#d2953f", fontWeight: "700" }}>DI: </span>The system should be usable by PRMs in a variety of conditions                                                                                                                </p>
                                            </div>
                                            <div className='user-needs-atl'>
                                                <div className="custom-bullet-point finding bullet-atl-finding">
                                                    <div className="finding-bullet finding-bullet-atl user-need-bullet">UN 7:</div>
                                                    <p>PRMs require a system that's easily usable across a wide age range</p>
                                                </div>
                                                <p className="small-finding-atl user-need-para">
                                                    <span style={{ color: "#d2953f", fontWeight: "700" }}>DI: </span>PRMs in all ages need to be able to use the system at ease                                                                                                                 </p>
                                            </div>

                                            {/* <div className="circle-wrapper finding-wrapper-atl">
                                                <div className="circle-solution-number finding-bullet-atl">1</div>
                                                <span className="circle-title finding-title-atl">Dependence on Family/Companions PRMs heavily rely on family members or travel companions for assistance throughout their airport journey.</span>
                                            </div>

                                            <div className="circle-wrapper finding-wrapper-atl">
                                                <div className="circle-solution-number finding-bullet-atl">2</div>
                                                <span className="circle-title finding-title-atl">Inconsistent Staff Support PRMs reported mixed experiences with airport staff, with support varying across different stages of travel.</span>
                                            </div>
                                            <div className="circle-wrapper finding-wrapper-atl">
                                                <div className="circle-solution-number finding-bullet-atl">3</div>
                                                <span className="circle-title finding-title-atl">Need for Better Communication There was a lack of clear upfront communication regarding accessibility features and procedures.</span>
                                            </div>
                                            <div className="circle-wrapper finding-wrapper-atl">
                                                <div className="circle-solution-number finding-bullet-atl">4</div>
                                                <span className="circle-title finding-title-atl">Difficulty Seeking Immediate Aid PRMs struggled to find timely assistance, especially during transitions between gates or while waiting in lines.</span>
                                            </div>
                                            <div className="circle-wrapper finding-wrapper-atl">
                                                <div className="circle-solution-number finding-bullet-atl">5</div>
                                                <span className="circle-title finding-title-atl">Emotional Weight of Challenges The cumulative travel challenges took an emotional toll on PRMs, with many feeling like a "burden."</span>
                                            </div>
                                            <div className="circle-wrapper finding-wrapper-atl">
                                                <div className="circle-solution-number finding-bullet-atl">6</div>
                                                <span className="circle-title finding-title-atl">Discomfort During Security Screening Security checkpoints were a source of significant discomfort and invasiveness for PRMs.</span>
                                            </div>
                                            <div className="circle-wrapper finding-wrapper-atl">
                                                <div className="circle-solution-number finding-bullet-atl">7</div>
                                                <span className="circle-title finding-title-atl">Accessibility Not Prioritized PRMs felt that airport infrastructure and services prioritized able-bodied individuals over their needs.</span>
                                            </div>
                                            <div className="circle-wrapper finding-wrapper-atl">
                                                <div className="circle-solution-number finding-bullet-atl">8</div>
                                                <span className="circle-title finding-title-atl">Strategic Travel Choices To enhance comfort and independence, PRMs made specific choices, such as purchasing first-class tickets or using preferred mobility aids.</span>
                                            </div> */}


                                            {/* <div className="grid-solution-phone">
                                                <div className="phonemockup">
                                                    <img
                                                        src={require('../src/assets/project1/screen1.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                                <div className="placeholder-image-column flex-direc">
                                                    <div className="circle-wrapper">
                                                        <div className="circle-solution-number">1</div>
                                                        <span className="circle-title">Enterprise Community Integration</span>
                                                    </div>

                                                    <p className="project-paragraph">
                                                        An onboarding page that connects users with businesses, featuring exclusive offers, community exploration, and direct messaging.                                            </p>
                                                </div>
                                            </div> */}


                                            {/* <div className="grid-solution-phone otherside">
                                                <div className="placeholder-image-column flex-direc">
                                                    <div className="circle-wrapper">
                                                        <div className="circle-solution-number">2</div>
                                                        <span className="circle-title">Community Discovery Interface</span>
                                                    </div>
                                                    <p className="project-paragraph">
                                                        Allows users to discover and join new communities through a user-friendly interface with search and filter options.                                            </p>
                                                </div>
                                                <div className="phonemockup">
                                                    <img
                                                        src={require('../src/assets/project1/explore.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>

                                            </div>


                                            <div className="grid-solution-phone">
                                                <div className="phonemockup">
                                                    <img
                                                        src={require('../src/assets/project1/details.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                                <div className="placeholder-image-column flex-direc">
                                                    <div className="circle-wrapper">
                                                        <div className="circle-solution-number">3</div>
                                                        <span className="circle-title">Enterprise Preview Profile</span>
                                                    </div>
                                                    <p className="project-paragraph">
                                                        Offers a detailed look at each enterprise, including member counts and response rates, encouraging community participation.                                            </p>
                                                </div>
                                            </div>

                                            <div className="grid-solution-phone otherside">
                                                <div className="placeholder-image-column flex-direc">
                                                    <div className="circle-wrapper">
                                                        <div className="circle-solution-number">4</div>
                                                        <span className="circle-title">Joined Communities Interface</span>
                                                    </div>
                                                    <p className="project-paragraph">
                                                        Displays communities the user has joined, enhancing engagement through announcement channels and Q&A sections.
                                                    </p>
                                                </div>
                                                <div className="phonemockup">
                                                    <img
                                                        src={require('../src/assets/project1/home.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>

                                            </div>


                                            <div className="grid-solution-phone">
                                                <div className="phonemockup">
                                                    <img
                                                        src={require('../src/assets/project1/chatf.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                                <div className="placeholder-image-column flex-direc">
                                                    <div className="circle-wrapper">
                                                        <div className="circle-solution-number">5</div>
                                                        <span className="circle-title">Admin Messaging Interface</span>
                                                    </div>
                                                    <p className="project-paragraph">
                                                        Facilitates direct communication with community admins, ensuring timely and personal interaction.                                            </p>
                                                </div>
                                            </div>

                                            <div>
                                            </div> */}
                                        </div>

                                        <div className="first-frame" >
                                            <div className="center-title">
                                                <div className="red-background orange-bg">
                                                    <h1 id="design" className="full-text red-text-atalnta third-frame-main" >Design <span className="red-text third-frame">Process</span></h1>
                                                </div>
                                            </div>

                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="metaphor" ref={sectionRefs['metaphor']} className="method">Ideation</h1>
                                            </div>
                                            <div className="design-metaphor">
                                                <p className="project-paragraph">
                                                    Our earliest brainstorming began with a metaphor exercise focused on understanding the context in which our designs existed. We created four ideas during this exercise, three of which would carry over to our future ideation sessions based on our interview findings.
                                                </p>
                                                {/* <div className=" ">
                                                    <img
                                                        src={require('../src/assets/project2/metephor.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                     <p className="project-paragraph caption-storyboard">
                                                    With the aid of the companion app, the user arrives prepared for the travel process.
                                                </p>
                                                </div> */}




                                                <div>
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/metephor.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard" style={{textAlign: 'center'}}>
                                                        Using metaphors in design activity
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="affinity-diagram" ref={sectionRefs['affinity-diagram']} className="method">Affinity diagram</h1>
                                            </div>
                                            <p className="project-paragraph">
                                                We used affinity mapping and interpretation sessions to settle on specific "Design Ideas." After we built our affinity diagram, we "walked the wall" to further identify holes in the current problem space and new ways of addressing them.                                            </p>
                                            {/* <iframe width="100%" height="432"  style = {{borderRadius: "30px"}} src="https://miro.com/app/embed/uXjVNl6Wh8A=/?pres=1&frameId=3458764581037725952&embedId=222093523818&autoplay=yep" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe> */}
                                            {/* <iframe width="100%" height="432" src="https://miro.com/app/live-embed/uXjVNl6Wh8A=/?pres=1&frameId=3458764581037725952&embedId=496485451935&autoplay=yep" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe> */}
                                            <iframe width="100%" height="432" style={{ borderRadius: "30px" }} src="https://miro.com/app/live-embed/uXjVNl6Wh8A=/?autoplay=true&moveToViewport=-1240,-1303,1928,178" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe>


                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="brainstorming" ref={sectionRefs['brainstorming']} className="method">Brainstorming</h1>
                                            </div>
                                            <p className="project-paragraph">
                                                All ideas were listed on the whiteboard, and we discussed how each addressed the holes and problems we had already identified. We drew arrows to show the relationship between different solutions, merging similar ideas and concluded ten different approaches.                                            </p>
                                            <div className="placeholder-image brainStorming">
                                                <img
                                                    src={require('../src/assets/project2/brainstorm.jpg')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>



                                        </div>

                                        <div className="first-frame">

                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="design-concepts" ref={sectionRefs['design-concepts']} className="method">First iteration - 8 design concepts</h1>
                                            </div>

                                            <p className="project-paragraph">
                                                After brainstorming, we settled on 8 possbile solutions. I then created sketches for each to visually explore our ideas.
                                            </p>

                                            <div className='concepts-grid' >
                                                <div>

                                                    <h2 className="concept-title">Concept 1: Kiosk</h2>
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/kiosk.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption">
                                                        With part of our findings revealing the lack of assistance between arrival and the check-in counter, the kiosk idea suggests an additional way of communication between PRMs and staff. These kiosks can be placed near the arrival for PRMs to check in, so staff can receive alerts about their arrival and provide immediate support.

                                                    </p>
                                                </div>
                                                <div>
                                                    <h2 className="concept-title">Concept 2: Mobility Device Free Rental</h2>
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/rental.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption">
                                                        PRMs often have to strategically plan which mobility devices to bring to the airport, which ones to be checked in with luggages, etc. The device rental services aim to provide a variety of free mobility devices for PRMs to choose from depending on their unique needs and abilities.
                                                    </p>
                                                </div>

                                                <div>
                                                    <h2 className="concept-title">Concept 3: Mobility Device Re-design</h2>
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/wheelchair.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption">
                                                        Part of the reason why PRMs are less mobile at the airport is because of the restrictions of mobility devices. Airport wheelchairs can only be pushed by other people, so re-imagining the design of a wheelchair can enhance PRMs’ self-reliance and fulfill the needs of PRMs’ with different abilities and age.
                                                    </p>
                                                </div>

                                                <div>
                                                    <h2 className="concept-title">Concept 4: Accessibility Checkpoints and Pathways</h2>
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/pathway.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption">
                                                        Having designated spaces and pathways is a great way of supporting PRMs by providing easily accessible services for PRMs with diverse abilities across age. When built in, these checkpoints and pathways also require minimal preparation by staff.                                                    </p>
                                                </div>

                                                <div>
                                                    <h2 className="concept-title">Concept 5: Digital Travel Companion</h2>
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/companion.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption">
                                                        A digital platform can provide information to PRMs before their arrival, streamline the process of requesting for accessible services, facilitate timely communication with stakeholders, and accept feedback after the trip. Such a platform is free and can easily be accessed by PRMs with different abilities and age. </p>                                                </div>

                                                <div>
                                                    <h2 className="concept-title">Concept 6: Staff Training</h2>
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/training.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption">
                                                        We observed instances of discrepancy of service quality at the airport. A more systematic staff training protocol will facilitate a smoother communication between PRMs and stakeholders and ensure staffs are more prepared in assisting PRMs. </p>                                                </div>


                                                <div>
                                                    <h2 className="concept-title">Concept 7: Pickup Service</h2>
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/pickup.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption">
                                                        PRMs often struggle to receive support upon arrival and have to figure out ways to reach the check-in counter by themselves. Another design idea would be setting up a system for PRMs to notify staff when they arrive so they can be picked up at the parking lot or where they are dropped off (by an uber, etc) to enhance communication and provide immediate support. This can be measured by customers’ perceived satisfaction and comfort.                                                    </p>
                                                </div>

                                                <div>
                                                    <h2 className="concept-title">Concept 8: Airport Signage</h2>
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/sign.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption">
                                                        In this concept, airport signage is located on the ground to allow for PRMs to see important messages they may miss due to height restrictions. Additionally, lower screen monitors at the eye level of PRMs will allow for an unobstructed view of arrivals and important messages. These changes increase PRMs self-reliance within the airport, which can be measured by PRMs’ perceived satisfaction about navigating the airport. </p>
                                                </div>


                                            </div>
                                        </div>

                                        <div className="first-frame">

                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="storyboards" ref={sectionRefs['storyboards']} className="method">Storyboards</h1>
                                            </div>

                                            <p className="project-paragraph">
                                                After sketching out all 8 design concepts, we chose to develop storyboards for two: the mobility device free rental and the digital travel companion. These were selected because they directly address essential needs: improving PRMs' autonomy at the airport and providing tailored, real-time assistance. These ideas showed the most promise in making a significant, positive impact on the travel experience for PRMs.
                                            </p>

                                            <h2 className="concept-title">Storyboard #1: Mobility Device Free Rental</h2>
                                            <div className='storyboard-grid' >
                                                <div>
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storyrent1.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        The user pre-selects the mobility device they want to use before arrival.
                                                    </p>
                                                </div>
                                                <div>
                                                    {/* <h2 className="concept-title">Concept 2: Mobility Device Free Rental</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storyrent2.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        When the user arrives at the airport, the staff is waiting with their chosen device.
                                                    </p>
                                                </div>

                                                <div>
                                                    {/* <h2 className="concept-title">Concept 3: Mobility Device Re-design</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storyrent3.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        The user takes the device and uses it to navigate the airport efficiently.                                                    </p>
                                                </div>

                                                <div>
                                                    {/* <h2 className="concept-title">Concept 3: Mobility Device Re-design</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storyrent4.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        The user takes the device and uses it to navigate the airport efficiently.                                                    </p>
                                                </div>

                                                <div>
                                                    {/* <h2 className="concept-title">Concept 3: Mobility Device Re-design</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storyrent5.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        The user takes the device and uses it to navigate the airport efficiently.                                                    </p>
                                                </div>

                                                <div>
                                                    {/* <h2 className="concept-title">Concept 3: Mobility Device Re-design</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storyrent6.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        The user takes the device and uses it to navigate the airport efficiently.                                                    </p>
                                                </div>



                                            </div>

                                            <h2 className="concept-title">Storyboard #2: Digital Travel Companion</h2>
                                            <div className='storyboard-grid' >
                                                <div>

                                                    {/* <h2 className="concept-title">Concept 1: Kiosk</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storydig1.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        With the aid of the companion app, the user arrives prepared for the travel process.                                                    </p>
                                                </div>
                                                <div>
                                                    {/* <h2 className="concept-title">Concept 2: Mobility Device Free Rental</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storydig2.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        The user checks in when they arrive at the airport and the staff is alerted.                                                    </p>
                                                </div>

                                                <div>
                                                    {/* <h2 className="concept-title">Concept 3: Mobility Device Re-design</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storydig3.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        The companion app can provide live updates relevant to airport navigation.                                                    </p>
                                                </div>

                                                <div>
                                                    {/* <h2 className="concept-title">Concept 3: Mobility Device Re-design</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storydig4.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        The user can upload doctor notes/medical history to the app for the TSA process (for example, a user with an implanted device who cannot go through metal detectors).                                                   </p>
                                                </div>

                                                <div>
                                                    {/* <h2 className="concept-title">Concept 3: Mobility Device Re-design</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storydig5.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        The user can request assistance through the app. An attendant can locate the user with the app’s location services.                                                    </p>
                                                </div>

                                                <div>
                                                    {/* <h2 className="concept-title">Concept 3: Mobility Device Re-design</h2> */}
                                                    <div className="concept-image">
                                                        <img
                                                            src={require('../src/assets/project2/storydig6.png')}
                                                            alt="Placeholder"
                                                            className="placeholder-image"
                                                        />
                                                    </div>
                                                    <p className="project-paragraph caption-storyboard">
                                                        The user can provide feedback on their trip through the app up to a certain time period after travel.                                                    </p>
                                                </div>



                                            </div>
                                        </div>

                                        <div className="first-frame">

                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="sketches" ref={sectionRefs['sketches']} className="method">Second Iteration - Sketches</h1>
                                            </div>

                                            <p className="project-paragraph">
                                                After developing the storyboards, it was clear that the digital travel companion concept held the most potential for improving PRMs' experiences. To maximize its effectiveness, we decided to integrate features from two other key concepts: the Kiosk and the Pickup Service. This integration aims to streamline communication between PRMs and airport staff from the moment of arrival, ensuring immediate support and enhancing overall satisfaction.
                                            </p>

                                            <div className='concepts-combined-grid'>
                                                <div className="concept-image">
                                                    <img
                                                        src={require('../src/assets/project2/companion.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                                <div className="plus">
                                                    <img
                                                        src={require('../src/assets/project2/plus.png')}
                                                        alt="Placeholder"
                                                        className=""
                                                    />
                                                </div>
                                                <div className="concept-image">
                                                    <img
                                                        src={require('../src/assets/project2/kiosk.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                                <div className="plus">
                                                    <img
                                                        src={require('../src/assets/project2/plus.png')}
                                                        alt="Placeholder"
                                                        className=""
                                                    />
                                                </div>
                                                <div className="concept-image">
                                                    <img
                                                        src={require('../src/assets/project2/pickup.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>



                                            </div>

                                            <p className="project-paragraph">
                                                Then, I started sketching out the screens for this combined solution.
                                            </p>

                                            <h2 className="concept-title">Main screen visualizing travel journey through milestones</h2>
                                            <div className="concept-image">
                                                <img
                                                    src={require('../src/assets/project2/sketchonboard.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>

                                            <h2 className="concept-title">Questions asked at each milestone to collect travel needs</h2>
                                            <div className="concept-image">
                                                <img
                                                    src={require('../src/assets/project2/onboarding1.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                            <div className="concept-image">
                                                <img
                                                    src={require('../src/assets/project2/onboarding2.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>


                                            <h2 className="concept-title">A customizable mascot as companion</h2>
                                            <div className="concept-image">
                                                <img
                                                    src={require('../src/assets/project2/mascot.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>


                                            <h2 className="concept-title">
                                                Emergency alert system</h2>
                                            <div className="concept-image">
                                                <img
                                                    src={require('../src/assets/project2/emergency.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>


                                            <h2 className="concept-title">Chatbox/prompt function</h2>
                                            <div className="concept-image">
                                                <img
                                                    src={require('../src/assets/project2/chatbox.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>












                                        </div>

                                        {/* <div className="first-frame">

                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="wireframes" ref={sectionRefs['wireframes']} className="method">Third Iteration - Wireframes</h1>
                                            </div>

                                            <p className="project-paragraph">
                                                Case study in development - wireframes coming soon.
                                            </p>
                                        </div> */}

                                        <div className="first-frame">

                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="prototype" ref={sectionRefs['prototype']} className="method">Prototype</h1>
                                            </div>

                                            {/* <p className="project-paragraph">
                                                Case study in development - prototype coming soon.
                                            </p> */}
                                            <p className="project-paragraph">
                                            After refining the designs based on the feedback sessions, I used Figma to create a hi-fi prototype. The main features are summarized below:                                </p>
                                        <div className="placeholder-image chart prototypes prototypes-atl">
                                            <img
                                                src={require('../src/assets/project2/prototype.png')}
                                                alt="Placeholder"

                                            />
                                        </div>
                                        </div>


                                        <div className="first-frame" >
                                            <div className="center-title">
                                                <div className="red-background orange-bg">
                                                    <h1 id="evaluate" className="full-text red-text-atalnta third-frame-main" >Evaluate <span className="red-text third-frame">Process</span></h1>
                                                </div>
                                            </div>

                                            <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="usability-testing" ref={sectionRefs['usability-testing']} className="method">Usability testing</h1>
                                            </div>
                                            <div className="">
                                                <p className="project-paragraph">
                                                    The core values of our app are accessibility and support. PRMs of all physical
                                                    and cognitive conditions should be able to use the app easily to receive support from other stakeholders, primarily accessibility staff. There are two design requirements we evaluated based on these values to examine the effectiveness of our system. To measure the success of these requirements, we used specific criteria such as ease of navigation for users with visual impairments and clarity in communication processes for PRMs. For accessibility, we examined the system’s usability for people with different cognitive conditions, specifically visual impairments. For support, we evaluated how well PRMs can communicate their conditions to stakeholders to ensure that they can receive support based on their unique needs.
                                                </p>

                                                <div className='test-grid'>
                                                <div>
                                                    <p className="project-paragraph title-eval">
                                                        Analysis
                                                    </p>
                                                    <p className="project-paragraph">
                                                        We interpreted a SUS score to assess the usability of our mascot app, Byrd by sharing a questionnaire with participants. The score was calculated based on NNgroup’s guidelines. Participants were asked to complete the SUS questionnaire after they experienced the normal version of the app. We wanted to assess ease of use and their understanding of what is on the screens without any visual hinderances. Overall, the usability of the app is positive, with a SUS score of 83% from the three participants.
                                                    </p>


                                                </div>
                                                <img
                                                    src={require('../src/assets/project2/susscore.png')}
                                                    alt="Placeholder"
                                                    
                                                />
                                            </div>

                                            </div>
                                        </div>

                                        <div className="first-frame" >
                                            <div className="center-title">
                                                <div className="red-background orange-bg">
                                                    <h1 id="conclusion" className="full-text red-text-atalnta third-frame-main" >Conclusion<span className="red-text third-frame"></span></h1>
                                                </div>
                                            </div>

                                            {/* <div className="bullet-point">
                                                <span className="bullet bullet-atl"></span>
                                                <h1 id="metaphor" ref={sectionRefs['metaphor']} className="method">Usability testing</h1>
                                            </div> */}
                                            <div className="">
                                                <p className="project-paragraph">
                                                Case study in development - conclusion coming soon.
                                                </p>
                                            </div>
                                        </div>





                                    </div>

                                </Container>

                            </div>
                        </div>


                    </div>

                </Container>
            </Box >
        </ThemeProvider >
    );
};

export default ProjectPage;

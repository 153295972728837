import React from 'react';
import { Box, Container, ThemeProvider } from '@mui/material';
import NavigationMenu from './NavigationMenu';
import { createTheme } from '@mui/material/styles';
import './LandingPage.css'; // Ensure you have a CSS file for ProjectPage if needed
import { useNavigate } from 'react-router-dom';
import 'animate.css';

const theme = createTheme({
    typography: {
        fontSize: 16,
    },
});

function AboutPage({ menuOpen, setMenuOpen, setActive }) {
    const navigate = useNavigate();

    const handleNavigation = (section) => {
        // Navigate to LandingPage with a query indicating the section
        navigate(`/?section=${section}`);
    };
    return (
        <ThemeProvider theme={theme}>
            <Box className="landing-container about" sx={{ flexGrow: 1, position: 'relative' }}>
                <Container maxWidth="lg" className="page-content">
                    <NavigationMenu
                        active='about'
                        setActive={setActive}
                        handleNavigation={handleNavigation}
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}
                    />
                    <Box className="content">
                        {/* Your Project Page Content Here */}
                        <div className = "section-active about-width">
                            <div className="title-container">
                                {/* <Box component="h1" className="main-title about-title">About</Box> */}
                                {/* <p>More information about me...</p> */}
                            </div>

                            <div className="about-section">

                                <div className="about-row">
                                    <div className="about-text">
                                        <Box component="h1" className="animate__animated animate__fadeInDown main-title about-title">About</Box>
                                        <p className = "animate__animated animate__fadeIn animate__delay-900ms">I find joy in designing, coding, and animating things. My approach to design is research-driven, ensuring that each design is not only aesthetically pleasing but also backed by solid understanding and analysis. Currently, my research interest lies in exploring the potential of generative AI in design, and how it can streamline the design process. One last thing...I'm a cat dad, with two super fluffy cats who love to make sure I am getting my work done.</p>
                                    </div>
                                    <div className="animate__animated animate__bounceIn about-image-container">
                                        <img className="about-image" src="https://i.postimg.cc/1zbdnX2q/ezgif-3-ae7e80b4f3.gif" alt="About Me" />
                                    </div>

                                </div>

                                <div className="image-section-wrapper">

                                    <Box component="h1" className="animate__animated animate__fadeInDown main-title not-working">When I am not working, I am...</Box>

                                    <div className="image-row">
                                        <div className="animate__animated animate__bounceIn image-text-container">
                                            <div className="about-image-container">
                                                <img className="about-image" src="https://i.postimg.cc/CMrJQWPP/ezgif-4-ded3f0ffa7.gif" alt="More About Me" />
                                            </div>
                                            <p className="animate__animated animate__fadeInDown image-description">Dancing Salsa</p>
                                        </div>
                                        <div className="animate__animated animate__bounceIn image-text-container">
                                            <div className="about-image-container">
                                                <img className="about-image" src="https://i.postimg.cc/V6yDDMKY/ezgif-4-f0ac75df6b.gif" alt="More About Me" />
                                            </div>
                                            <p className="animate__animated animate__fadeInDown image-description">Freediving</p>
                                        </div>
                                        <div className="animate__animated animate__bounceIn image-text-container">
                                            <div className="about-image-container">
                                                <img className="about-image" src="https://i.postimg.cc/LXJh6CfT/ezgif-2-e0068b1d4d.gif" alt="More About Me" />
                                            </div>
                                            <p className="animate__animated animate__fadeInDown animate__delay-1200ms image-description">Watching memes</p>
                                        </div>

                                        {/* Repeat for other images */}
                                    </div>
                                </div>


                                {/* Row 1: Text on Left, Image on Right */}


                                {/* Row 2: Image on Left, Text on Right */}
                                {/* <div className="about-image-container">
                  <img className="about-image" src="https://i.postimg.cc/6qr56s2Z/637fa35fe9e61f31b7e97142-MS-teams.jpg" alt="More About Me" />
                </div>
                <div className="about-image-container">
                  <img className="about-image" src="https://i.postimg.cc/6qr56s2Z/637fa35fe9e61f31b7e97142-MS-teams.jpg" alt="More About Me" />
                </div>
                <div className="about-image-container">
                  <img className="about-image" src="https://i.postimg.cc/6qr56s2Z/637fa35fe9e61f31b7e97142-MS-teams.jpg" alt="More About Me" />
                </div> */}

                            </div>
                        </div>

                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
}

export default AboutPage;

import React from 'react';
import { Box, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFile, faComment } from '@fortawesome/free-solid-svg-icons';
import Rive from '@rive-app/react-canvas';
import helloRiv from './assets/interactive_cta-2.riv';
import { useNavigate } from 'react-router-dom';
import 'animate.css';


function NavigationMenu({ active, setActive, handleNavigation, menuOpen, setMenuOpen }) {


    const navigate = useNavigate();

    const handleButtonClick = (value) => {
        if (value === 'work' || value === 'play') {
            navigate(`/?section=${value}`);
        } else if (value === 'about') {
            navigate('/about');
        }
        setMenuOpen(false);
    }

    const handleCircleHeadClick = () => {
        navigate('/'); // Navigate to the "Work" page when the circle head is clicked
    };


    // Styles for active and inactive buttons here
    const activeStyle = {
        textTransform: 'none',
        fontWeight: '700',
        padding: '10px 20px',
        backgroundColor: 'rgba(178, 178, 178, 0.15)', // Red background for active button
        border: '2px solid rgba(59, 59, 50, 0)',
        color: '#fff', // White text for active button
        borderRadius: '35px', // Rounded corners for active button
        ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Darker red on hover for active button
            // color: 'white', // White text on hover for active button
        },
    };

    const inactiveStyle = {
        textTransform: 'none',
        fontWeight: '700',
        padding: '10px 20px',
        backgroundColor: 'transparent', // Transparent background for inactive button
        color: '#fff', // White text for inactive button
        borderRadius: '35px', // Rounded corners for inactive button
        border: '2px solid rgba(59, 59, 50, 0)',
    };



    return (
        <Box className="menu-top" sx={{ p: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="animate__animated animate__bounceIn circle head" onClick={handleCircleHeadClick}>
                <Rive src={helloRiv} stateMachines="State Machine 1" autoplay='true' className="rive-animation" />
            </div>
            <Box className="animate__animated animate__flipInY switch">
                <Button onClick={() => handleButtonClick('work')} sx={active === 'work' ? activeStyle : inactiveStyle}>
                    Work
                </Button>
                <Button onClick={() => handleButtonClick('play')} sx={active === 'play' ? activeStyle : inactiveStyle}>
                    Play
                </Button>
            </Box>
            <div className="animate__animated animate__bounceIn wrapper">
                <input type="checkbox" checked={menuOpen} onChange={() => setMenuOpen(!menuOpen)} />
                <div className="fab"></div>
                <div className="fac">
                    <button onClick={() => navigate('/about')}>
                        <FontAwesomeIcon icon={faUser} />
                    </button>
                    <button onClick={() => window.open('https://drive.google.com/file/d/1AIk3TiVhHUQcofM_D6FkESGknWe4s3cX/view?usp=sharing', '_blank')}>
                        <FontAwesomeIcon icon={faFile} />
                    </button>
                    <button onClick={() => {/* handle action for faComment */ }}>
                        <a href="mailto:masery3@gatech.edu" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <FontAwesomeIcon icon={faComment} />
                        </a>
                    </button>

                </div>
            </div>
        </Box>
    );
}

export default NavigationMenu;

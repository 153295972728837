import { React, useEffect, useState, useRef } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Box, Container, Divider, Grid, ThemeProvider, Typography } from '@mui/material';
import NavigationMenu from './NavigationMenu';
import { createTheme } from '@mui/material/styles';
import './LandingPage.css';
import './ProjectMicro.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { fontSize } from '@mui/system';
import 'animate.css';



{/* <FontAwesomeIcon icon="fa-solid fa-circle-chevron-right" /> */ }

// Reuse the theme from LandingPage for consistency
const theme = createTheme({
    typography: {
        fontSize: 16,
    },
});


const ProjectPage = ({ menuOpen, setMenuOpen, setActive }) => {
    const [isFloatingVisible, setIsFloatingVisible] = useState(false);
    const overviewRef = useRef(null);
    const navigate = useNavigate();
    const [userClicked, setUserClicked] = useState(false);


    const handleScroll = () => {
        if (overviewRef.current) {
            const rect = overviewRef.current.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
            setIsFloatingVisible(isVisible);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Call it to check the initial position
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleNavigation = (section) => {
        navigate(`/?section=${section}`);
    };


    // Directly set the active section on click without additional logic
    const handleSectionClick = (sectionId) => {
        setUserClicked(true); // Indicate that this change was triggered by a user click
        setActiveSection(sectionId);
        scrollToSection(sectionId);

        // Optionally, open the corresponding collapsible based on the clicked section
        const collapsibleSectionToOpen = mapContentSectionToSidebarSection(sectionId);
        if (collapsibleSectionToOpen) {
            setOpenSections({
                ...Object.keys(openSections).reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                }, {}),
                [collapsibleSectionToOpen]: true,
            });
        }
    };


    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    // State to control the visibility of each collapsible content
    const [openSections, setOpenSections] = useState({});

    // Function to toggle collapsible section
    const toggleSection = (sectionId) => {
        setOpenSections(prevState => ({
            ...prevState,
            [sectionId]: !prevState[sectionId]
        }));
    };

    // Ref for the content to measure its height for animation
    const contentRef = useRef({});

    const handleToggleAndScroll = (toggleSectionId, scrollSectionId) => {
        toggleSection(toggleSectionId); // Toggle the visibility of the section
        setTimeout(() => { // Set a timeout to wait for the toggle animation
            scrollToSection(scrollSectionId); // Scroll to the section after the toggle
        }, 0); // Adjust the timeout duration based on your toggle animation duration
    };

    const [activeSection, setActiveSection] = useState('');
    const sectionRefs = {
        'project-overview': useRef(null),
        'stakeholders': useRef(null),
        'problem-statement': useRef(null),
        'possible-solution': useRef(null),

        'design-thinking-process': useRef(null),
        'survey': useRef(null),
        'product-walkthrough': useRef(null),
        'competitive-analysis': useRef(null),
        'interview': useRef(null),
        'key-findings': useRef(null),
        'user-needs': useRef(null),
        'empathy-map': useRef(null),
        'design-implications': useRef(null),
        'feature-matrix': useRef(null),
        'affinity-diagram': useRef(null),
        'initial-sketches': useRef(null),
        'iteration feedback': useRef(null),
        'wireframes': useRef(null),
        'iteration-feedback': useRef(null),
        'design-system': useRef(null),
        'prototype': useRef(null),
        'heuristic-evaluation': useRef(null),
        'usability': useRef(null),



    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            let visibleSections = entries.filter(entry => entry.isIntersecting).map(entry => entry.target.id);

            if (visibleSections.length && !userClicked) { // Check if change is not due to user click
                let currentSection = visibleSections[0];
                setActiveSection(currentSection);

                // Logic to open only the corresponding collapsible section
                const collapsibleSectionToOpen = mapContentSectionToSidebarSection(currentSection);
                if (collapsibleSectionToOpen) {
                    setOpenSections(prevState => {
                        let newState = Object.keys(prevState).reduce((acc, key) => {
                            acc[key] = false; // Close all other sections
                            return acc;
                        }, {});
                        newState[collapsibleSectionToOpen] = true; // Open the current section
                        return newState;
                    });
                }
            } else {
                setUserClicked(false); // Reset userClicked back to false for automatic changes
            }
        }, { rootMargin: '0px', threshold: 0.5 });

        Object.values(sectionRefs).forEach(ref => {
            if (ref.current) observer.observe(ref.current);
        });

        return () => Object.values(sectionRefs).forEach(ref => {
            if (ref.current) observer.unobserve(ref.current);
        });
    }, [userClicked]); // Include userClicked in the dependency array



    // Function to map content section IDs to sidebar section keys
    function mapContentSectionToSidebarSection(contentSectionId) {
        // Example mapping, update according to your actual IDs and keys
        const mapping = {


            'design-thinking-process': 'design-thinking-process',

            'survey': 'empathize',
            'product-walkthrough': 'empathize',
            'competitive-analysis': 'empathize',
            'interview': 'empathize',

            'key-findings': 'define',
            'user-needs': 'define',
            'empathy-map': 'define',
            'design-implications': 'define',

            'feature-matrix': 'ideate',
            'affinity-diagram': 'ideate',
            'initial-sketches': 'ideate',
            'iteration feedback': 'ideate',

            'wireframes': 'design',
            'iteration-feedback': 'design',
            'design-system': 'design',
            'prototype': 'design',

            'heuristic-evaluation': 'test',
            'usability': 'test',
            // Add other mappings here
        };
        return mapping[contentSectionId] || contentSectionId;
    }


    return (
        <ThemeProvider theme={theme}>
            <Box className="landing-container project" sx={{ flexGrow: 1, position: 'relative' }}>


                <Container maxWidth={false} className="page-content" disableGutters >





                    {/* Your Project Page Content Here */}
                    <div className="project1-section1">

                        <Container maxWidth={"lg"}  >
                            <NavigationMenu
                                active='project'
                                handleNavigation={handleNavigation}
                                menuOpen={menuOpen}
                                setMenuOpen={setMenuOpen}
                            />
                            <div className="slide1">
                                <Grid container spacing={2}>
                                    {/* Left column with placeholder image */}
                                    <Grid item xs={6}>
                                        <img

                                            src="https://i.postimg.cc/cHdWWGRq/removal-ai-eaaa8e8d-ceef-4b20-9e1b-e5f57b72cb37-mockup-scene.png"
                                            alt="Placeholder"
                                            className='project-image'
                                            onAnimationEnd={() => {

                                            }}
                                        />


                                    </Grid>
                                    {/* Right column with two titles */}
                                    <Grid item xs={6} >
                                        <div className="title-container  animate__animated animate__fadeIn animate__delay-900ms">
                                            <div className="title-bg">
                                                <Typography variant="h5">
                                                    UX Case Study
                                                </Typography>
                                            </div>
                                            <Typography variant="h5">
                                                Empowering Consumers to Connect with Businesses
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>

                            </div>
                            <Container style={{ maxWidth: '980px' }}  >
                                <Divider style={{ marginBottom: '10px' }} />
                                <div className="animate__animated  animate__fadeIn animate__delay-900ms">
                                    <div className='info-case-grid ' >
                                        {/* <Grid item>
                                        <h1 className="item-text" style={{ width: '100%' }}>
                                            Role
                                        </h1>
                                        <p style={{ width: '100%' }}>
                                            Lead Designer
                                        </p>
                                        <p style={{ width: '100%' }}>
                                            User Researcher
                                        </p>
                                    </Grid> */}
                                        <div>
                                            {/* Column 2 */}
                                            <h1 className="item-text">
                                                Project Context
                                            </h1>
                                            <p>
                                                Industry Partner
                                            </p>
                                            <p>
                                                Aug - Dec 2023
                                            </p>
                                            <p>
                                                Lead UX Designer, User Researcher
                                            </p>


                                        </div>
                                        <div >
                                            {/* Column 3 */}
                                            <h1 className="item-text">
                                                Team
                                            </h1>
                                            <div className='team-grid'>
                                                <div style={{ paddingRight: '1rem' }}>
                                                    <p style={{ fontWeight: '600' }} >
                                                        Georgia Tech Team

                                                    </p>
                                                    <p style={{ fontSize: '13px', transform: 'translateY(-0.6rem)' }}>
                                                        2 UX Designers / 2 UX Researchers
                                                    </p>
                                                </div>
                                                {/* <Divider orientation="vertical" flexItem /> */}
                                                <div>
                                                    <p style={{ fontWeight: '600', transform: 'translateY(-0.7rem)' }}>
                                                        Microsoft Team
                                                    </p>

                                                    <p style={{ fontSize: '13px', transform: 'translateY(-1.3rem)' }}>
                                                        2 UX Designers / 1 UX Researcher / 1 PM
                                                    </p>
                                                </div>
                                            </div>





                                        </div>
                                        <div>
                                            {/* Column 5 */}
                                            <h1 className="item-text" >
                                                Tools
                                            </h1>
                                            <p >
                                                Figma, Miro
                                            </p>
                                            <p >
                                                Qualtrics, Jira                                   </p>
                                            {/* <p>
                                            Miro, Jira                                    </p>
                                            <p>
                                            Jira                                     </p> */}
                                        </div>
                                        {/* <div>
                                        <h1 className="item-text">
                                            Skills
                                        </h1>
                                        <p >
                                            User Interview, Survey, Product Walkthrough Competitive Analysis, Task Analysis, Empathy Map, Feature Matrix, Affinity Diagram, sketch low-fi concepts, wire-frame, high fi, heuristic evaluation, usability test
                                        </p>

                                    </div> */}



                                    </div>
                                    <div className='process'>
                                        {/* Column 5 */}
                                        <h1 className="item-text" >
                                            Process
                                        </h1>
                                        <p >
                                            User Interview, Survey, Product Walkthrough, Competitive Analysis, Task Analysis, Empathy Map, Feature Matrix, Affinity Diagram, Sketch, Low-Fi Concepts, Wireframe, High Fi, Heuristic Evaluation, Usability Test                                    </p>



                                    </div>
                                    <div>
                                        {/* Column 5 */}
                                        <h1 className="item-text" >
                                            Role
                                        </h1>
                                        <div className='role-uxdr'>
                                            <p >
                                                <span style={{ fontWeight: 700 }}>Research Phase:</span> I conducted 7 semi-structured interviews, performed competitive analysis on 3 market products, constructed the survey and interview questions, developed task analysis, created data visualizations, and developed an empathy map. I participated in and led the creation of an affinity diagram.
                                            </p>
                                            <p >
                                                <span style={{ fontWeight: 700 }}>Design Phase:</span> I led ideation and concept creation, crafted wireframes and UI designs, and guided 5 user feedback sessions. During the final design and evaluation phase, I developed a high-fidelity prototype and orchestrated one heuristic evaluation and one user testing session.
                                            </p>
                                        </div>


                                    </div>

                                </div>

                                {/* <h1 className="item-text" style={{ width: '100%' }}>
                                    Tools
                                </h1>
                                <p style={{ width: '100%' }}>
                                    Figma
                                </p> */}
                                {/* <p className="project-paragraph">
                                As the lead designer on the team, I was responsible for all design and prototyping tasks.

Research Phase: I led surveys and interviews to gather user insights.

Design Phase: I worked on ideation, developed wireframes, crafted the design system, and led six user feedback sessions.

Final Design and Evaluation Phase: I created high-fidelity prototypes, led two heuristic evaluations, and conducted three user testing sessions
                                </p> */}
                            </Container>


                        </Container>
                    </div>
                    <div className="content-wrapper">

                        {isFloatingVisible && (
                            <div
                                className="floating-container"
                                style={{
                                    position: 'fixed',
                                    top: '50%',
                                    left: '46%',
                                    transform: 'translate(-345%, -65%)',
                                    zIndex: 1000,
                                    // Additional styles for the floating container
                                }}
                            >
                                {/* Floating container content */}

                                <div className="index-title">
                                    <p>Quick read:</p>
                                </div>


                                <div className={`custom-bullet index  ${activeSection === 'project-overview' ? 'active-class' : ''}`} onClick={() => handleSectionClick('project-overview')}>
                                    <p>Project overview</p>
                                </div>
                                <div className={`custom-bullet index  ${activeSection === 'stakeholders' ? 'active-class' : ''}`} onClick={() => handleSectionClick('stakeholders')}>
                                    <p>Stakeholders</p>
                                </div>

                                <div className={`custom-bullet index  ${activeSection === 'problem-statement' ? 'active-class' : ''}`} onClick={() => handleSectionClick('problem-statement')}>
                                    <p>Problem statement</p>
                                </div>

                                <div className={`custom-bullet index  ${activeSection === 'possible-solution' ? 'active-class' : ''}`} onClick={() => handleSectionClick('possible-solution')}>
                                    <p>Project outcome</p>
                                </div>

                                <div className="index-title">
                                    <p>Delve in:</p>
                                </div>



                                <div className={`custom-bullet index ${activeSection === 'design-thinking-process' ? 'active-class' : ''}`} onClick={() => handleSectionClick('design-thinking-process')}>
                                    <p>Design process</p>
                                </div>
                                <div>
                                    <div onClick={() => handleToggleAndScroll('empathize', 'empathize')} className="collapsible-header">
                                        <div className="custom-bullet index indextitlecolla " >
                                            <p>Empathize</p>
                                        </div>
                                        <div className="icon-circle-background">
                                            {/* <FontAwesomeIcon icon={openSections['empathize'] ? faChevronDown : faChevronRight} className="fa-icon" /> */}
                                            <FontAwesomeIcon icon={faChevronRight} className={`fa-icon ${openSections['empathize'] ? 'icon-rotate open' : 'icon-rotate'}`} />
                                        </div>
                                    </div>


                                    <div
                                        ref={el => contentRef.current['empathize'] = el}
                                        style={{
                                            color: 'red',
                                            maxHeight: openSections['empathize'] ? `${contentRef.current['empathize']?.scrollHeight}px` : '0',
                                            transition: 'max-height 0.5s ease-in-out',
                                            overflow: 'hidden',
                                        }}
                                        className="collapsible-content"
                                    >


                                        {/* <div
                                            id="survey"
                                            ref={sectionRefs['survey']}
                                            className={activeSection === 'survey' ? 'active-class' : ''}
                                        > */}
                                        <div ref={sectionRefs['survey']} className={`custom-bullet index subindex ${activeSection === 'survey' ? 'active-class' : ''}`} onClick={() => handleSectionClick('survey')}>

                                            <p>Surveys</p>
                                        </div>

                                        <div ref={sectionRefs['product-walkthrough']} className={`custom-bullet index subindex ${activeSection === 'product-walkthrough' ? 'active-class' : ''}`} onClick={() => handleSectionClick('product-walkthrough')}>
                                            <p>Product Walkthrough</p>
                                        </div>

                                        <div ref={sectionRefs['competitive-analysis']} className={`custom-bullet index subindex ${activeSection === 'competitive-analysis' ? 'active-class' : ''}`} onClick={() => handleSectionClick('competitive-analysis')}>
                                            <p>Competitive Analysis</p>
                                        </div>
                                        <div ref={sectionRefs['interview']} className={`custom-bullet index subindex ${activeSection === 'interview' ? 'active-class' : ''}`} onClick={() => handleSectionClick('interview')}>
                                            <p>Interviews</p>
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <div onClick={() => handleToggleAndScroll('define', 'define')} className="collapsible-header">
                                        <div className="custom-bullet index indextitlecolla ">
                                            <p>Define</p>
                                        </div>
                                        <div className="icon-circle-background">
                                            {/* <FontAwesomeIcon icon={openSections['empathize'] ? faChevronDown : faChevronRight} className="fa-icon" /> */}
                                            <FontAwesomeIcon icon={faChevronRight} className={`fa-icon ${openSections['define'] ? 'icon-rotate open' : 'icon-rotate'}`} />
                                        </div>
                                    </div>


                                    <div
                                        ref={el => contentRef.current['define'] = el}
                                        style={{
                                            color: 'red',
                                            maxHeight: openSections['define'] ? `${contentRef.current['define']?.scrollHeight}px` : '0',
                                            transition: 'max-height 0.5s ease-in-out',
                                            overflow: 'hidden',
                                        }}
                                        className="collapsible-content"
                                    >


                                        <div ref={sectionRefs['key-findings']} className={`custom-bullet index subindex ${activeSection === 'key-findings' ? 'active-class' : ''}`} onClick={() => handleSectionClick('key-findings')}>
                                            <p>Key findings</p>
                                        </div>
                                        <div ref={sectionRefs['user-needs']} className={`custom-bullet index subindex ${activeSection === 'user-needs' ? 'active-class' : ''}`} onClick={() => handleSectionClick('user-needs')}>
                                            <p>User needs</p>
                                        </div>
                                        <div ref={sectionRefs['empathy-map']} className={`custom-bullet index subindex ${activeSection === 'empathy-map' ? 'active-class' : ''}`} onClick={() => handleSectionClick('empathy-map')}>
                                            <p>Empathy map</p>
                                        </div>
                                        <div ref={sectionRefs['design-implications']} className={`custom-bullet index subindex ${activeSection === 'design-implications' ? 'active-class' : ''}`} onClick={() => handleSectionClick('design-implications')}>
                                            <p>Design implications</p>
                                        </div>
                                    </div>

                                </div>


                                <div>
                                    <div onClick={() => handleToggleAndScroll('ideate', 'ideate')} className="collapsible-header">
                                        <div className="custom-bullet index indextitlecolla ">
                                            <p>Ideate</p>
                                        </div>
                                        <div className="icon-circle-background">
                                            {/* <FontAwesomeIcon icon={openSections['empathize'] ? faChevronDown : faChevronRight} className="fa-icon" /> */}
                                            <FontAwesomeIcon icon={faChevronRight} className={`fa-icon ${openSections['ideate'] ? 'icon-rotate open' : 'icon-rotate'}`} />
                                        </div>
                                    </div>


                                    <div
                                        ref={el => contentRef.current['ideate'] = el}
                                        style={{
                                            color: 'red',
                                            maxHeight: openSections['ideate'] ? `${contentRef.current['ideate']?.scrollHeight}px` : '0',
                                            transition: 'max-height 0.5s ease-in-out',
                                            overflow: 'hidden',
                                        }}
                                        className="collapsible-content"
                                    >

                                        <div className={`custom-bullet index subindex ${activeSection === 'feature-matrix' ? 'active-class' : ''}`} onClick={() => handleSectionClick('feature-matrix')}>
                                            <p>Feature matrix</p>
                                        </div>
                                        <div className={`custom-bullet index subindex ${activeSection === 'affinity-diagram' ? 'active-class' : ''}`} onClick={() => handleSectionClick('affinity-diagram')}>
                                            <p>Affinity diagram</p>
                                        </div>

                                        <div className={`custom-bullet index subindex ${activeSection === 'initial-sketches' ? 'active-class' : ''}`} onClick={() => handleSectionClick('initial-sketches')}>
                                            <p>Initial sketches</p>
                                        </div>
                                        <div className={`custom-bullet index subindex ${activeSection === 'iteration feedback' ? 'active-class' : ''}`} onClick={() => handleSectionClick('iteration feedback')}>
                                            <p>Iteration feedback</p>
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <div onClick={() => handleToggleAndScroll('design', 'design')} className="collapsible-header">
                                        <div className="custom-bullet index indextitlecolla ">
                                            <p>Design</p>
                                        </div>
                                        <div className="icon-circle-background">
                                            {/* <FontAwesomeIcon icon={openSections['empathize'] ? faChevronDown : faChevronRight} className="fa-icon" /> */}
                                            <FontAwesomeIcon icon={faChevronRight} className={`fa-icon ${openSections['design'] ? 'icon-rotate open' : 'icon-rotate'}`} />
                                        </div>
                                    </div>


                                    <div
                                        ref={el => contentRef.current['design'] = el}
                                        style={{
                                            color: 'red',
                                            maxHeight: openSections['design'] ? `${contentRef.current['design']?.scrollHeight}px` : '0',
                                            transition: 'max-height 0.5s ease-in-out',
                                            overflow: 'hidden',
                                        }}
                                        className="collapsible-content"
                                    >

                                        <div className={`custom-bullet index subindex ${activeSection === 'wireframes' ? 'active-class' : ''}`} onClick={() => handleSectionClick('wireframes')}>
                                            <p>Wireframes</p>
                                        </div>
                                        <div className={`custom-bullet index subindex ${activeSection === 'iteration-feedback' ? 'active-class' : ''}`} onClick={() => handleSectionClick('iteration-feedback')}>
                                            <p>Iteration feedback</p>
                                        </div>
                                        <div className={`custom-bullet index subindex ${activeSection === 'design-system' ? 'active-class' : ''}`} onClick={() => handleSectionClick('design-system')}>
                                            <p>Design system</p>
                                        </div>
                                        <div className={`custom-bullet index subindex ${activeSection === 'prototype' ? 'active-class' : ''}`} onClick={() => handleSectionClick('prototype')}>
                                            <p>Prototype</p>
                                        </div>
                                    </div>

                                </div>


                                <div>
                                    <div onClick={() => handleToggleAndScroll('test', 'test')} className="collapsible-header">
                                        <div className="custom-bullet index indextitlecolla ">
                                            <p>Test</p>
                                        </div>
                                        <div className="icon-circle-background">
                                            {/* <FontAwesomeIcon icon={openSections['empathize'] ? faChevronDown : faChevronRight} className="fa-icon" /> */}
                                            <FontAwesomeIcon icon={faChevronRight} className={`fa-icon ${openSections['test'] ? 'icon-rotate open' : 'icon-rotate'}`} />
                                        </div>
                                    </div>


                                    <div
                                        ref={el => contentRef.current['test'] = el}
                                        style={{
                                            color: 'red',
                                            maxHeight: openSections['test'] ? `${contentRef.current['test']?.scrollHeight}px` : '0',
                                            transition: 'max-height 0.5s ease-in-out',
                                            overflow: 'hidden',
                                        }}
                                        className="collapsible-content"
                                    >

                                        <div className={`custom-bullet index subindex ${activeSection === 'heuristic-evaluation' ? 'active-class' : ''}`} onClick={() => handleSectionClick('heuristic-evaluation')}>
                                            <p>Heuristic evaluation</p>
                                        </div>
                                        <div className={`custom-bullet index subindex ${activeSection === 'usability' ? 'active-class' : ''}`} onClick={() => handleSectionClick('usability')}>
                                            <p>Usability testing</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}

                        <div className="project-overview-content">

                            <div className="project1-section2">
                                {/* <Container style={{ maxWidth: '1100px' }} > */}
                                <Container style={{ maxWidth: '980px' }} >
                                    <div className="first-frame">
                                        <div className="center-title">
                                            <div className="red-background">
                                                <h1 id="project-overview" ref={sectionRefs['project-overview']} className="full-text" >Project <span className="red-text">Overview</span></h1>
                                            </div>
                                        </div>
                                        <p className="project-paragraph">
                                            Microsoft came to us with <span style={{ fontWeight: '700' }}>the challenge of enhancing interactions between organizations and external customers through Microsoft Teams</span>. In response, our project aimed to identify and design solutions for key use cases leveraging the platform. Recognizing the distinct functionalities between Teams' paid and free versions, we specifically explored how to utilize Communities within the free version for effective organization-customer interactions. Our focus was on pinpointing essential functionalities needed for these interactions and proposing a model that leverages Teams' existing communication capabilities to boost engagement between organizations and their customers.                                </p>
                                    </div>


                                    <div ref={overviewRef}>
                                        <div>
                                            <div className="grid-container-context">

                                                <div className="first-frame">
                                                    <div className="users">
                                                        <img
                                                            src={require('../src/assets/project1/use.png')}
                                                            alt="Placeholder"
                                                            className="small-icon-image"
                                                        />
                                                    </div>
                                                    <div className="center-title context-main">
                                                        <div className="red-background context">
                                                            <h1 className="full-text-context" ><span className="red-text">Context of Use</span></h1>
                                                        </div>
                                                    </div>
                                                    <p className="project-paragraph contextOfUse">
                                                        Microsoft Teams serves varied scenarios - from marketing and product launches to feedback, community building, and customer service, emphasizing its adaptability in diverse communicative contexts.

                                                    </p>
                                                </div>

                                                <div className="first-frame">
                                                    <div className="users">
                                                        <img
                                                            src={require('../src/assets/project1/disparity.png')}
                                                            alt="Placeholder"
                                                            className="small-icon-image"
                                                        />
                                                    </div>
                                                    <div className="center-title context-main">
                                                        <div className="red-background context">
                                                            <h1 className="full-text-context" ><span className="red-text">Business Situation</span></h1>
                                                        </div>
                                                    </div>
                                                    <p className="project-paragraph contextOfUse">
                                                        A disparity in communication channels, as customers engage via 'Communities' and enterprises through 'Channels,' highlighting the urgent need for integrated communication solutions.

                                                    </p>
                                                </div>

                                                <div className="first-frame">
                                                    <div className="users">
                                                        <img
                                                            src={require('../src/assets/project1/bridge.png')}
                                                            alt="Placeholder"
                                                            className="small-icon-image"
                                                        />
                                                    </div>
                                                    <div className="center-title context-main">
                                                        <div className="red-background context">
                                                            <h1 className="full-text-context" ><span className="red-text">Significance</span></h1>
                                                        </div>
                                                    </div>

                                                    <p className="project-paragraph contextOfUse">
                                                        By bridging the communication gap, businesses can achieve enhanced customer satisfaction, gain deeper insights, and approach product and service delivery more effectively and informatively.                                        </p>
                                                </div>

                                            </div>
                                        </div>



                                        <div>
                                            <div className="center-title">
                                                <div className="red-background ">
                                                    <h1 id="stakeholders" ref={sectionRefs['stakeholders']} className="full-text" >Who is <span className="red-text">Involved?</span></h1>
                                                </div>
                                            </div>
                                            <div className="grid-container involved">
                                                <div className="first-frame">
                                                    <div className="users">
                                                        <img
                                                            src={require('../src/assets/project1/users.png')}
                                                            alt="Placeholder"
                                                            className="small-icon-image"
                                                        />
                                                    </div>
                                                    <div className="center-title context-main">
                                                        <div className="red-background context">
                                                            <h1 className="full-text-context" ><span className="red-text">Stakeholders</span></h1>
                                                        </div>
                                                    </div>


                                                    <div className="project-paragraph custom stakeholders rightholder contextOfUse">
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Business owners</p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Community Admins </p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Customer Services</p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Marketing Team</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="first-frame">
                                                    <div className="users">
                                                        <img
                                                            src={require('../src/assets/project1/target.png')}
                                                            alt="Placeholder"
                                                            className="small-icon-image"
                                                        />
                                                    </div>
                                                    <div className="center-title context-main">
                                                        <div className="red-background context">
                                                            <h1 className="full-text-context" ><span className="red-text">Target User</span></h1>
                                                        </div>
                                                    </div>

                                                    <div className="project-paragraph custom stakeholders contextOfUse">
                                                        <img src={require('../src/assets/project1/focus.png')} alt="Overlay Image" style={{ position: 'absolute', top: '-78px', left: '-24px', zIndex: '10' }} />
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Regular customers looking for new ways to interact with businesses who are using Teams for Life (free version)</p>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>


                                        <div className="first-frame">
                                            <div className="center-title">
                                                <div className="red-background">
                                                    <h1 id="problem-statement" ref={sectionRefs['problem-statement']} className="full-text third-frame-main" >Problem <span className="red-text third-frame">Statement</span></h1>
                                                </div>
                                            </div>
                                            <p className="project-paragraph">
                                                Businesses lack ways to communicate with their customers effectively via Microsoft Teams. Such communication is essential for businesses in times of marketing, product launches, feedback gathering, community building, and customer service. Without a way for businesses and customers to communicate effortlessly, businesses will likely lose valuable information about their users, and customers will also miss important updates from their service providers.
                                            </p>
                                        </div>

                                        <div className="first-frame">
                                            <div className="placeholder-image-column hmw">
                                                <img
                                                    src={require('../src/assets/project1/hmw.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                        </div>

                                        <div className="first-frame">
                                            <div className="center-title">
                                                <div className="red-background">
                                                    <h1 id="possible-solution" ref={sectionRefs['possible-solution']} className="full-text" >Project <span className="red-text">Outcome</span></h1>
                                                </div>
                                            </div>
                                            <p className="project-paragraph">
                                                Our solution introduces new features to the Communities function of Microsoft Teams' free version, aiming to simplify and improve the way businesses connect with external customers. We've developed a series of interfaces to foster efficient communication and engagement. Here's how each component works to make these interactions more intuitive and effective:                             </p>

                                            <div className="grid-solution-phone">
                                                <div className="phonemockup">
                                                    <img
                                                        src={require('../src/assets/project1/screen1.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                                <div className="placeholder-image-column flex-direc">
                                                    <div className="circle-wrapper">
                                                        <div className="circle-solution-number">1</div>
                                                        <span className="circle-title">Enterprise Community Integration</span>
                                                    </div>

                                                    <p className="project-paragraph">
                                                        An onboarding page that connects users with businesses, featuring exclusive offers, community exploration, and direct messaging.                                            </p>
                                                </div>
                                            </div>


                                            <div className="grid-solution-phone otherside">
                                                <div className="placeholder-image-column flex-direc">
                                                    <div className="circle-wrapper">
                                                        <div className="circle-solution-number">2</div>
                                                        <span className="circle-title">Community Discovery Interface</span>
                                                    </div>
                                                    <p className="project-paragraph">
                                                        Allows users to discover and join new communities through a user-friendly interface with search and filter options.                                            </p>
                                                </div>
                                                <div className="phonemockup">
                                                    <img
                                                        src={require('../src/assets/project1/explore.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>

                                            </div>


                                            <div className="grid-solution-phone">
                                                <div className="phonemockup">
                                                    <img
                                                        src={require('../src/assets/project1/details.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                                <div className="placeholder-image-column flex-direc">
                                                    <div className="circle-wrapper">
                                                        <div className="circle-solution-number">3</div>
                                                        <span className="circle-title">Enterprise Preview Profile</span>
                                                    </div>
                                                    <p className="project-paragraph">
                                                        Offers a detailed look at each enterprise, including member counts and response rates, encouraging community participation.                                            </p>
                                                </div>
                                            </div>

                                            <div className="grid-solution-phone otherside">
                                                <div className="placeholder-image-column flex-direc">
                                                    <div className="circle-wrapper">
                                                        <div className="circle-solution-number">4</div>
                                                        <span className="circle-title">Joined Communities Interface</span>
                                                    </div>
                                                    <p className="project-paragraph">
                                                        Displays communities the user has joined, enhancing engagement through announcement channels and Q&A sections.
                                                    </p>
                                                </div>
                                                <div className="phonemockup">
                                                    <img
                                                        src={require('../src/assets/project1/home.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>

                                            </div>


                                            <div className="grid-solution-phone">
                                                <div className="phonemockup">
                                                    <img
                                                        src={require('../src/assets/project1/chatf.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                                <div className="placeholder-image-column flex-direc">
                                                    <div className="circle-wrapper">
                                                        <div className="circle-solution-number">5</div>
                                                        <span className="circle-title">Admin Messaging Interface</span>
                                                    </div>
                                                    <p className="project-paragraph">
                                                        Facilitates direct communication with community admins, ensuring timely and personal interaction.                                            </p>
                                                </div>
                                            </div>

                                            <div>
                                            </div>
                                        </div>

                                        <div className="first-frame" >
                                            <div className="center-title">
                                                <div className="red-background">
                                                    <h1 id="design-thinking-process" ref={sectionRefs['design-thinking-process']} className="full-text third-frame-main" >Design Thinking <span className="red-text third-frame">Process</span></h1>
                                                </div>
                                            </div>
                                            <p className="project-paragraph">
                                                The design thinking journey for enhancing Microsoft Teams started with a deep dive into user needs and concluded with comprehensive testing to fulfill those requirements. Each step, from defining issues via surveys to empathizing with users, from creative ideation to realizing concepts with wireframes and prototypes, was shaped by user input and iterative feedback.                                </p>
                                            <div className="placeholder-image designThinking">
                                                <img
                                                    src={require('../src/assets/project1/process.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                        </div>

                                        <div className="first-frame">
                                            <div className="center-title">
                                                <div className="red-background">
                                                    <h1 id="empathize" className="full-text third-frame-main" >Empathize <span className="red-text third-frame">Phase</span></h1>
                                                </div>
                                            </div>
                                            {/* Survey */}
                                            <h1 id="survey" ref={sectionRefs['survey']} className="header-story" >First, we listened to what users had to say</h1>

                                            <div className="bullet-point">
                                                <span className="bullet"></span>
                                                <h1 className="method">Survey</h1>
                                            </div>

                                            <p className="project-paragraph">
                                                We kicked off our exploration into user interactions with Microsoft Teams by launching a targeted survey. Reaching out through four online communities, <span style={{ fontWeight: '700' }}>we engaged 77 participants</span>, delving into their experiences and preferences with a set of <span style={{ fontWeight: '700' }}>16 questions</span>. This survey offered us a window into the varied ways users engage with Teams for business communication, while also providing a comparative perspective against other platforms. The insights gathered here were instrumental in guiding us towards meaningful conversations in our follow-up interviews and competitive analysis.
                                            </p>

                                            <div className="placeholder-images-container">
                                                <div className="placeholder-image-column questionOne">
                                                    <img
                                                        src={require('../src/assets/project1/questionOne.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                                <div className="placeholder-image-column pieOne">
                                                    <img
                                                        src={require('../src/assets/project1/pie1.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                            </div>

                                            <div className="placeholder-images-container">
                                                <div className="placeholder-image-column questionOne questionTwo">
                                                    <img
                                                        src={require('../src/assets/project1/questionTwo.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                                <div className="placeholder-image-column pieOne">
                                                    <img
                                                        src={require('../src/assets/project1/pie2.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                            </div>
                                            <div className="placeholder-image chart">
                                                <img
                                                    src={require('../src/assets/project1/chart.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                            <Box className="key-insights">
                                                <Container maxWidth="lg" className="key-insights-content">
                                                    <h1 className="key-insight-title">Survey key findings:</h1>
                                                    <div className="project-paragraph custom">
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>People use Teams in work-related context the most (60% use it for internal collaboration with team members)</p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Community feature in Teams for life (free plan) is unfamiliar to many of our participants (39% never use it this way)</p>
                                                        </div>
                                                    </div>
                                                </Container>
                                            </Box>


                                            {/* Product Walkthrough */}
                                            <h1 id="product-walkthrough" ref={sectionRefs['product-walkthrough']} className="header-story" >Then, we took a closer look at how users navigate Teams</h1>

                                            <div className="bullet-point">
                                                <span className="bullet"></span>
                                                <h1 className="method">Product walkthrough</h1>
                                            </div>

                                            <p className="project-paragraph">
                                                After gathering valuable insights from our survey, we shifted our focus to a hands-on exploration of Microsoft Teams. We conducted an in-depth product walkthrough, <span style={{ fontWeight: '700' }}>assessing two fundamental processes: setting up a new community and joining an existing one</span>. By performing a <span style={{ fontWeight: '700' }}>Hierarchical Task Analysis</span>, we immersed ourselves in the Teams environment, scrutinizing the system's usability and learnability. This step-by-step examination of user tasks provided us with a clear lens to view the actual user journey, revealing both the strengths and areas for improvement in the Teams interface.
                                            </p>

                                            <div className="placeholder-image chart">
                                                <img
                                                    src={require('../src/assets/project1/hts.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>

                                            <Box className="key-insights">
                                                <Container maxWidth="lg" className="key-insights-content">
                                                    <h1 className="key-insight-title">Product Walkthrough key findings:</h1>
                                                    <div className="project-paragraph custom">
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Creating your own community is straightforward.</p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Discovering and joining existing community is more difficult (Even if the community has “Visibility” setting on).</p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Direct message someone in the community takes more steps.</p>
                                                        </div>
                                                    </div>
                                                </Container>
                                            </Box>


                                            {/* Competitive analysis */}
                                            <h1 id="competitive-analysis" ref={sectionRefs['competitive-analysis']} className="header-story" >Next, we broadened our view by comparing Teams to its competitors</h1>

                                            <div className="bullet-point">
                                                <span className="bullet"></span>
                                                <h1 className="method">Competitive analysis</h1>
                                            </div>

                                            <p className="project-paragraph">
                                                Building upon the insights gained from both the survey and the product walkthrough, we next conducted a competitive analysis. Here, we revisited Microsoft Teams in the context of its <span style={{ fontWeight: '700' }}>key competitors such as Slack, Discord, WhatsApp, GroupMe, Facebook, Instagram, and Zoom, alongside Microsoft Teams</span>. Our objective was not just to compare features but to understand each platform's unique approach to business communication. Through this exploration, we aimed to pinpoint where Teams could potentially stand out or improve, ensuring our project targets the most impactful areas for development.                                    </p>

                                            <div className="placeholder-image chart table">
                                                <img
                                                    src={require('../src/assets/project1/table.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>

                                            <Box className="key-insights">
                                                <Container maxWidth="lg" className="key-insights-content">
                                                    <h1 className="key-insight-title">Competitive analysis key findings:</h1>
                                                    <div className="project-paragraph custom">
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Teams has most of the features that’s offered by other platforms.</p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Teams community is a newer product - it has only been available for 1 year.</p>
                                                        </div>
                                                        <div className="custom-bullet-point">
                                                            <div className="custom-bullet"></div>
                                                            <p>Various pricing plans is more complicated, which might have contributed to difficulties in communication between free (customers) & paid (enterprise) users due to difference in feature availability.</p>
                                                        </div>
                                                    </div>
                                                </Container>
                                            </Box>


                                            {/* Interview */}
                                            <h1 id="interview" ref={sectionRefs['interview']} className="header-story" >And then, we dug deeper through user interviews</h1>

                                            <div className="bullet-point">
                                                <span className="bullet"></span>
                                                <h1 className="method">Semi-structured interview</h1>
                                            </div>



                                            <div className="interview-container">
                                                <p className="project-paragraph">
                                                    With the competitive landscape and user experience of Microsoft Teams already explored, we moved to a more personal level of research: interviews. Selecting <span style={{ fontWeight: '700' }}>four participants</span> who showed interest in our survey, we conducted these interviews both online via Teams and in person. This method allowed us to delve deeper, collecting qualitative insights about users’ perceptions and experiences. These interviews provided us with a richer, more nuanced understanding of how real users interact with and perceive Microsoft Teams, especially in the context of business communication. <span style={{ fontWeight: '700' }}>This qualitative depth </span>added a valuable layer to our research, bringing us closer to the actual experiences and needs of Microsoft Teams users.                                    </p>
                                                <div className="placeholder-image-column interviewPic">
                                                    <img
                                                        src={require('../src/assets/project1/chris.png')}
                                                        alt="Placeholder"
                                                        className="placeholder-image"
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <Box className="key-insights">
                                            <Container maxWidth="lg" className="key-insights-content">
                                                <h1 className="key-insight-title">Interview key findings:</h1>
                                                <div className="project-paragraph custom">
                                                    <div className="custom-bullet-point">
                                                        <div className="custom-bullet"></div>
                                                        <p>Many users do not know what plan they are using (also supported by survey).</p>
                                                    </div>
                                                    <div className="custom-bullet-point">
                                                        <div className="custom-bullet"></div>
                                                        <p>Users rely on other communication tools to reach out to people external to their organization.</p>
                                                    </div>
                                                </div>
                                            </Container>
                                        </Box>

                                        {/* Define */}
                                        <div className="center-title">
                                            <div className="red-background">
                                                <h1 id="define" className="full-text third-frame-main" >Define <span className="red-text third-frame">Phase</span></h1>
                                            </div>
                                        </div>


                                        {/* Highlighting Central Challenges */}
                                        <h1 id="key-findings" ref={sectionRefs['key-findings']} className="header-story" >With insights in hand, we identified key challenges</h1>

                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Key findings</h1>
                                        </div>

                                        <p className="project-paragraph">
                                            After diving into user surveys, navigating Microsoft Teams ourselves, comparing it with other platforms, and talking directly with users, we pinpointed two main challenges:
                                        </p>

                                        <Box className="key-insights key-findings">
                                            <Container maxWidth="lg" className="key-insights-content">
                                                <div className="project-paragraph custom findingsmove">
                                                    <div>
                                                        <div className="custom-bullet-point finding">
                                                            {/* <div className="custom-bullet bullet-finding"></div> */}
                                                            <div className="finding-bullet">1
                                                            </div>
                                                            <p>Difficulty in Reaching External Individuals</p>

                                                        </div>
                                                        <p className="small-finding">
                                                            Users struggled to connect with people outside their immediate network on Microsoft Teams.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <div className="custom-bullet-point finding">
                                                            <div className="finding-bullet">2
                                                            </div>
                                                            <p>Low Awareness of Teams’ Community Usage</p>

                                                        </div>
                                                        <p className="small-finding">
                                                            There was a general lack of knowledge about how Teams could be used for community engagement.
                                                        </p>
                                                    </div>
                                                </div>
                                            </Container>
                                        </Box>

                                        {/* Identifying What Users Really Need */}
                                        <h1 id="user-needs" ref={sectionRefs['user-needs']} className="header-story" >This led us to clearly define what users really need</h1>

                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">User needs</h1>
                                        </div>

                                        <p className="project-paragraph">
                                            Recognizing the challenges faced by users, two pivotal needs emerged as our design focus:

                                        </p>
                                        <div className="project-paragraph user-needs">

                                            <p><span><FontAwesomeIcon icon={faCircleChevronRight} className="chevron" /></span><span className="user-need">Discover and Engage with New Communities: </span>Users needed an easier way to find and interact with new groups.</p>
                                            <p><span><FontAwesomeIcon icon={faCircleChevronRight} className="chevron" /></span><span className="user-need">Seamless Communication with External Entities: </span>It was vital for users to have straightforward communication channels with people outside their organization.</p>
                                        </div>

                                        {/* Visualizing User Experiences */}
                                        <h1 id="empathy-map" ref={sectionRefs['empathy-map']} className="header-story" >Using these insights, we mapped out our users' perspectives</h1>

                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Empathy map</h1>
                                        </div>

                                        <p className="project-paragraph">
                                            From the collected data, an empathy map was crafted to visualize and understand users' feelings, thoughts, and experiences with Microsoft Teams. This pivotal step helped to highlight not just the direct feedback but also the underlying emotions and needs, directing the design decisions towards more empathetic and user-centric solutions.
                                        </p>

                                        <div className="empathy">
                                            <img
                                                src={require('../src/assets/project1/empathy.png')}
                                                alt="Placeholder"
                                                className="placeholder-image"
                                            />
                                        </div>


                                        {/* Crafting Our Design Strategy*/}
                                        <h1 id="design-implications" ref={sectionRefs['design-implications']} className="header-story" >Which shaped our strategy moving forward</h1>

                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Design implications</h1>
                                        </div>

                                        <p className="project-paragraph">
                                            Transitioning from understanding deep user needs and the current landscape, the focus shifted to forming actionable strategies. These strategies aimed to directly address the identified needs. The approach was twofold:
                                        </p>
                                        <div className="project-paragraph user-needs">

                                            <p><span><FontAwesomeIcon icon={faCircleChevronRight} className="chevron" /></span><span className="user-need">Enhance Discoverability of New Communities: </span>Introducing an intuitive onboarding process to guide users in exploring and joining new communities.</p>
                                            <p><span><FontAwesomeIcon icon={faCircleChevronRight} className="chevron" /></span><span className="user-need">Streamline Business-to-Customer Communication: </span>Focusing on improving direct messaging features within Teams to facilitate better communication with community admins.</p>
                                        </div>


                                        {/* Ideate */}
                                        <div className="center-title">
                                            <div className="red-background">
                                                <h1 id="ideate" className="full-text third-frame-main" >Ideate <span className="red-text third-frame">Phase</span></h1>
                                            </div>
                                        </div>


                                        {/* Highlighting Central Challenges */}
                                        <h1 id="feature-matrix" ref={sectionRefs['feature-matrix']} className="header-story" >Armed with a strategy, we mapped out key features</h1>

                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Feature matrix</h1>
                                        </div>

                                        <p className="project-paragraph">
                                            After establishing our user-centric strategies, we leveraged a feature matrix to juxtapose Microsoft Teams with leading platforms. This comparison honed the design direction, ensuring that the solutions not only align with user needs but also stand out in the competitive landscape. The matrix served as a blueprint for integrating the most effective features into Teams, enhancing the platform's community and communication functionalities.
                                        </p>

                                        <div className="placeholder-image matrix">
                                            <img
                                                src={require('../src/assets/project1/matrix.jpg')}
                                                alt="Placeholder"

                                            />
                                        </div>

                                        <h1 id="affinity-diagram" ref={sectionRefs['affinity-diagram']} className="header-story" >Distilling insights and shaping our design concepts</h1>
                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Affinity diagram</h1>
                                        </div>
                                        <p className="project-paragraph">
                                            Utilizing an affinity diagram, I analyzed the interview data, uncovering patterns and themes that might not have been evident through surveys or competitive analysis alone. This helped distill the vast amount of information and insights gathered into coherent, actionable design concepts, bridging the gap between broad ideation and focused design directions.
                                        </p>
                                        {/* <iframe width="100%" height="432"  style = {{borderRadius: "30px"}} src="https://miro.com/app/embed/uXjVNl6Wh8A=/?pres=1&frameId=3458764581037725952&embedId=222093523818&autoplay=yep" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe> */}
                                        {/* <iframe width="100%" height="432" src="https://miro.com/app/live-embed/uXjVNl6Wh8A=/?pres=1&frameId=3458764581037725952&embedId=496485451935&autoplay=yep" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe> */}
                                        <iframe width="100%" height="432" style={{ borderRadius: "30px" }} src="https://miro.com/app/live-embed/uXjVNl6Wh8A=/?autoplay=true&moveToViewport=-1240,-1303,1928,178" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe>


                                        <h1 id="initial-sketches" ref={sectionRefs['initial-sketches']} className="header-story" >Turning ideas into initial sketches was our next step</h1>
                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Initial sketches</h1>
                                        </div>

                                        <div className='grid-container-sketches'>
                                            <p className="project-paragraph">
                                                After strategizing based on comprehensive insights and the competitive landscape, the next step was to bring those strategies to life. Initial sketches provided a visual form to the functionalities envisioned for Microsoft Teams. These early representations began to address the key challenges of community discovery and efficient external communication, setting the stage for refinement and user testing.
                                            </p>

                                            <div className="initial">
                                                <img
                                                    src={require('../src/assets/project1/initial.jpg')}
                                                    alt="Placeholder"
                                                />
                                            </div>
                                        </div>


                                        <div className="placeholder-image chart sketchpro">
                                            <img
                                                src={require('../src/assets/project1/sketchpro.png')}
                                                alt="Placeholder"
                                                className="placeholder-image"
                                            />
                                        </div>


                                        {/* <p className="project-paragraph" style={{ fontWeight: '800', transform: 'translateY(1rem)' }}>
                                            Community discoverability:
                                        </p> */}

                                        <div className="grid-sketches">
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch1rat.png')}
                                                    alt="Placeholder"

                                                />
                                            </div>
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch1.png')}
                                                    alt="Placeholder"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-sketches sketch-first">
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch2.png')}
                                                    alt="Placeholder"
                                                />
                                            </div>
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch2rat.png')}
                                                    alt="Placeholder"

                                                />
                                            </div>

                                        </div>

                                        <div className="grid-sketches">
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch3rat.png')}
                                                    alt="Placeholder"

                                                />
                                            </div>
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch3.png')}
                                                    alt="Placeholder"
                                                />
                                            </div>
                                        </div>

                                        <div className="grid-sketches sketch-first">
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch4.png')}
                                                    alt="Placeholder"
                                                />
                                            </div>
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch4rat.png')}
                                                    alt="Placeholder"

                                                />
                                            </div>

                                        </div>

                                        {/* <p className="project-paragraph" style={{ fontWeight: '800', transform: 'translateY(1rem)' }}>
                                            Community Search & Filters:
                                        </p>

                                        <div className="grid-sketches">
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch1.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch1.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch1.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch1.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                        </div>


                                        <p className="project-paragraph" style={{ fontWeight: '800', transform: 'translateY(1rem)' }}>
                                            Directly messaging the enterprise:
                                        </p>

                                        <div className="grid-sketches">
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch1.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch1.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch1.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                            <div className="sketches">
                                                <img
                                                    src={require('../src/assets/project1/sketch1.png')}
                                                    alt="Placeholder"
                                                    className="placeholder-image"
                                                />
                                            </div>
                                        </div> */}

                                        <h1 id="iteration feedback" ref={sectionRefs['iteration feedback']} className="header-story" >Refining those sketches based on feedback came after</h1>
                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Iteration feedback</h1>
                                        </div>

                                        <p className="project-paragraph">
                                            I conducted iterative feedback sessions with participants who use Teams, <span style={{ fontWeight: '700' }}>focusing on community discoverability, search and filters, and direct messaging</span>. Sessions were held online or in-person, tailored to participant preference, with recordings or transcripts captured for analysis.</p>

                                        <p className="project-paragraph scenario">
                                            Scenario: Imagine you are testing out some new layout options for a free version of Microsoft Teams called Communities. We need your feedback and will be asking you some questions about how you navigate the pages. All you need to do is provide honest feedback, think aloud, and tell us why you are making certain decisions. Remember, there are no right or wrong answers!
                                        </p>

                                        <p className="project-paragraph">
                                            After receiving feedback, I organized a session with the team to interpret the data, where each member presented their findings and notes to the group. We then collectively analyzed the information to identify common patterns among our participants.                                </p>

                                        {/* Design */}
                                        <div className="center-title">
                                            <div className="red-background">
                                                <h1 id="design" className="full-text third-frame-main" >Design <span className="red-text third-frame">Phase</span></h1>
                                            </div>
                                        </div>


                                        {/* Highlighting Central Challenges */}
                                        <h1 id="wireframes" ref={sectionRefs['wireframes']} className="header-story" >With a clear vision, we started crafting wireframes</h1>
                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Wireframes</h1>
                                        </div>

                                        <p className="project-paragraph">
                                            In response to user feedback on sketches, a series of wireframes were developed. These wireframes show the user's journey through the new features, ensuring the path to community engagement and communication is as seamless as possible. This user journey guide serves to demonstrate the proposed flow within Microsoft Teams, from the initial welcome screen to the various interaction points such as the interest selection, community discovery, and direct messaging interfaces.
                                        </p>

                                        <div className="placeholder-image chart table">
                                            <img
                                                src={require('../src/assets/project1/wireframewakj.jpg')}
                                                alt="Placeholder"
                                                className="placeholder-image"
                                            />
                                        </div>

                                        <p className="project-paragraph">
                                            Following the user journey's framework, I started wireframing to transform initial concepts into detailed screen flows, ensuring a user-friendly navigation from the Welcome Page to key features like Community and Chat Pages, laying the groundwork for the development phase.
                                        </p>

                                        <div className="placeholder-image chart table">
                                            <img
                                                src={require('../src/assets/project1/wireframes.png')}
                                                alt="Placeholder"
                                                className="placeholder-image"
                                            />
                                        </div>


                                        <h1 id="iteration-feedback" ref={sectionRefs['iteration-feedback']} className="header-story" >Iterating on those designs brought them closer to perfection</h1>
                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Iteration feedback</h1>
                                        </div>

                                        <p className="project-paragraph">
                                            For the wireframe iteration feedback, I tasked participants with navigating through our system's key functionalities to evaluate their completion success and time efficiency. Our participants, all familiar with the Teams App, were consistently involved across sketch and wireframe sessions, offering us valuable insights into our iterative progress. Sessions involved presenting wireframes within a mock scenario, encouraging participants to articulate their thoughts as they interacted with the interface. I then probed further with specific questions on features, ensuring all feedback was captured via notes or recordings for team analysis.
                                        </p>

                                        <h1 id="design-system" ref={sectionRefs['design-system']} className="header-story" >Developing a design system ensured the solutions were cohesive</h1>
                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Design system</h1>
                                        </div>
                                        <p className="project-paragraph">
                                            In developing the design system, the focus was on consistency and usability, guiding the visual and interactive elements across the new Microsoft Teams features. This framework included typography, color schemes, and UI components, all aimed at enhancing user experience while ensuring ease of use and accessibility.
                                        </p>

                                        <div className="placeholder-image chart designsys">
                                            <img
                                                src={require('../src/assets/project1/designsys.png')}
                                                alt="Placeholder"
                                                className="placeholder-image"
                                            />
                                        </div>


                                        <h1 id="prototype" ref={sectionRefs['prototype']} className="header-story" >Finally, I brought everything together in a working prototype</h1>
                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Prototype</h1>
                                        </div>
                                        <p className="project-paragraph">
                                            After refining the designs based on the feedback sessions, I used Figma to create a prototype that showcases the the new features designed for Microsoft Teams. The main features are summarized below:                                </p>
                                        <div className="placeholder-image chart prototypes ">
                                            <img
                                                src={require('../src/assets/project1/proto.png')}
                                                alt="Placeholder"

                                            />
                                        </div>
                                        <div className="placeholder-image chart explanation ">
                                            <img
                                                src={require('../src/assets/project1/explanation.png')}
                                                alt="Placeholder"

                                            />
                                        </div>







                                        {/* Test */}
                                        <div className="center-title">
                                            <div className="red-background">
                                                <h1 id="test" className="full-text third-frame-main" >Test <span className="red-text third-frame">Phase</span></h1>
                                            </div>
                                        </div>


                                        {/* add */}
                                        <h1 id="heuristic-evaluation" ref={sectionRefs['heuristic-evaluation']} className="header-story" >The journey continued with rigorous testing</h1>
                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Expert Testing: Heuristic Evaluation</h1>
                                        </div>

                                        <div className='test-grid'>
                                            <div>
                                                <p className="project-paragraph title-eval">
                                                    Process
                                                </p>
                                                <p className="project-paragraph">
                                                    We recruited 3 experts to perform Heuristic Evaluation in order to find usability problems in our design before user testing. They inspected each screen in depth using Nielsen's 10 usability heuristic principles.                                        </p>


                                            </div>
                                            <img
                                                src={require('../src/assets/project1/heuri1.png')}
                                                alt="Placeholder"
                                                className="placeholder-image"
                                            />
                                        </div>

                                        <div className='test-grid'>
                                            <div>
                                                <p className="project-paragraph title-eval">
                                                    Analysis
                                                </p>
                                                <p className="project-paragraph">
                                                    We compiled notes from 3 heuristic evaluation interviews, synthesized the most common usability problems, averaged the severity score for all three experts for each function.
                                                    The greater the score, the more severe violation of the principle. We averaged the score of all functions to get the mean score for our entire system.                                                                                          </p>


                                            </div>
                                            <img
                                                src={require('../src/assets/project1/heuri2.png')}
                                                alt="Placeholder"
                                                className="placeholder-image"
                                            />
                                        </div>




                                        {/* Highlighting Central Challenges */}
                                        <h1 id="usability" ref={sectionRefs['usability']} className="header-story" >Concluding our process with in-depth usability testing</h1>
                                        <div className="bullet-point">
                                            <span className="bullet"></span>
                                            <h1 className="method">Moderated Usability Testing</h1>
                                        </div>
                                        <p className="project-paragraph">
                                            After revising our prototype per experts’ feedback from Heuristic Evaluation, we wanted to do another round of testing with our target users to examine the usability of our project. At this stage, we had two primary goals that we wanted to accomplish. First, we want to directly observe our target users’ behavior and reaction when going over the onboarding process to investigate if the current user flow is intuitive and easy to understand. Then, we want to understand users’ preference on how they might initiate a direct chat with a community admin among the 2 versions that we had.</p>

                                        <div className='test-grid'>
                                            <div>
                                                <p className="project-paragraph title-eval">
                                                    Process
                                                </p>
                                                <p className="project-paragraph">
                                                    The study included 6 tasks that best represent the main functionalities of our prototype, with each task followed by a short follow-up question asking them about what was easy or hard, or if anything unexpected happened to better understand their thoughts. The tasks were as followed:</p>

                                                <div className='process-tasks'>
                                                    <p className="project-paragraph">
                                                        1- You are a huge fan of a restaurant called Sea Breeze, and you want to join their community. How would you do that?
                                                    </p>
                                                    <p className="project-paragraph">
                                                        2- How would you check on updates from the Sea Breeze community? 
                                                    </p>
                                                    <p className="project-paragraph">
                                                        3- What about the announcement or Q&A channels? How would you check those? 
                                                    </p>
                                                    <p className="project-paragraph">
                                                        4- You want to order food delivery from Sea Breeze, but you are not sure if they offer that. You want to talk directly to a community admin about this. How would you do that?  
                                                    </p>
                                                    <p className="project-paragraph">
                                                        5- The admin from Sea Breeze has replied to your message. Where would you go to check the message?  
                                                    </p>
                                                    <p className="project-paragraph">
                                                        6- You want to explore more communities of interest. How would you do that? 
                                                    </p>
                                                </div>




                                            </div>
                                            <img
                                                src={require('../src/assets/project1/usability1.png')}
                                                alt="Placeholder"
                                                // className="placeholder-image"
                                            />
                                        </div>

                                        <div className='test-grid'>
                                            <div>
                                                <p className="project-paragraph title-eval">
                                                    Analysis
                                                </p>
                                                <p className="project-paragraph">
                                                    Analysis was done first by calculating the success rate. Then, we gathered as a group to share the notes taken during each session. As we went through the notes, we looked for patterns that emerged in at least two or more participants and noted them down as key findings.
                                                    The overall success rate of each task looks like the following. Task 1, 3, and 5 had a success rate of 100%, Task 2 was 20%, Task 4 was 0%, and Task 6 was 60%.
                                                </p>


                                            </div>
                                            <img
                                                src={require('../src/assets/project1/sucrate.png')}
                                                alt="Placeholder"
                                                // className="placeholder-image"
                                            />
                                        </div>

                                        {/* <p className="project-paragraph">
                                            Case study in development - usability testing results coming soon.
                                        </p> */}

                                    </div>

                                </Container>

                            </div>
                        </div>


                    </div>

                </Container>
            </Box >
        </ThemeProvider >
    );
};

export default ProjectPage;

import React, { useState, useEffect } from 'react';
import { Box, Button, Container, ThemeProvider } from '@mui/material';
import './LandingPage.css';
import { createTheme } from '@mui/material/styles';
import logo from './assets/maze_game.riv';
import logo2 from './assets/gang2.riv';
import Rive from '@rive-app/react-canvas';
import NavigationMenu from './NavigationMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import 'animate.css';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

// Theme customization
const theme = createTheme({
  typography: {
    fontSize: 16,
  },
});


function LandingPage({ menuOpen, setMenuOpen }) {

  // State to handle active page and menu
  const [active, setActive] = useState('work');
  // const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Check URL for section parameter
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    if (section === 'work' || section === 'play') {
      setActive(section);
    }
  }, [location]);


  const handleButtonClick = (value) => {
    setActive(value);
  };

  const getVisibility = (section) => active === section ? 'block' : 'none';

  // navigatinf 
  const navigate = useNavigate();

  // const menuOpen = Boolean(menuAnchorEl);

  // Determine content alignment and height based on active page
  const contentStyle = active === 'play' ? {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Align to top for 'play' page
    alignItems: 'center',
  } : {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref5, inView5] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref6, inView6] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref7, inView7] = useInView({ triggerOnce: true, threshold: 0 });

  const [ref8, inView8] = useInView({ triggerOnce: true, threshold: 0 });
  const [ref9, inView9] = useInView({ triggerOnce: true, threshold: 0 });


  return (
    <ThemeProvider theme={theme}>
      <Box className={`landing-container ${active}`} sx={{ flexGrow: 1, position: 'relative' }}>
        <Container maxWidth="lg" className="page-content">
          <NavigationMenu
            active={active}
            setActive={setActive}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
          />
          {/* Main content with dynamic style */}
          <Box className="content" sx={contentStyle}>
            <div className={active === 'work' ? 'section-active' : 'section-hidden'}>
              <>
                {/* Work Page Content */}
                <div className="title-container">
                  <Box component="h1" className="animate__animated animate__flipInX main-title">Hello, I am Amin!</Box>
                  <div className="subtitle-container">
                    <Box component="h2" className="animate__animated animate__fadeInDown subtitle-left">A UX designer with experience in coding and animaiton</Box>
                    <Box component="h2" className="animate__animated animate__fadeInDown subtitle-right">@ Georgia Tech</Box>
                  </div>

                </div>
                <div className="animate__animated animate__bounceIn animate__delay-0.8s RiveContainer Cat">
                  <Rive
                    src={logo2}
                    stateMachines="Animation 1"
                    autoplay='true'
                  />
                </div>


                <Box component="h1" className={`case-studies ${inView8 ? 'animate__animated animate__fadeIn animate__delay-0.8s' : ''}`} ref={ref8}>Selected Work</Box>
                <div className="cards-container">
                  <div className={`card ${inView1 ? 'animate__animated animate__flipInX' : ''}`} ref={ref1}>

                    <div className="card-text">
                      <div className="card-para">
                        {/* <img src={require('../src/assets/project1/microsoft.png')} alt="Microsoft Logo" style="width: 40px; height: 40px; vertical-align: middle;"> */}
                        <img src={require('../src/assets/project1/microsoft.png')} alt="Microsoft Logo" className="microsoft-logo" />
                        <h3 className="microsoft-title">Microsoft</h3>

                        <div className="tags">
                          <p>UX Research</p>
                          <p>UX Design</p>
                          <p>B2C</p>
                        </div>
                        <p>Enhancing Microsoft Teams with direct customer-business communication.</p>
                      
                        {/* <p>Designing a new Microsoft Teams feature for direct customer-business communication, updates, and community engagement.</p> */}
                      </div>
                      <Button variant="contained" className="see-project-button" onClick={() => window.open('/project', '_blank')}>Learn more</Button>
                      {/* <Button variant="contained" className="see-project-button"  onClick={() => window.open("https://www.figma.com/proto/m4ZK1kLAYGaEqPi39UYpCy/Untitled?type=design&node-id=1-1799&scaling=contain&page-id=1%3A1088&starting-point-node-id=1%3A1799&mode=design&t=euRQzFTBxaojtqiy-1", '_blank')}>See Project</Button> Add this line */}
                    </div>
                    <div className="card-image mockupmicro">
                      <img src={require('../src/assets/project1/mockupmicro.png')} alt={`Card`} />
                    </div>
                  </div>
                </div>

                {/* ATL Airport */}
                <div className=" cards-container ">
                  <div className={`card ${inView2 ? 'animate__animated animate__flipInX' : ''}`} ref={ref2}>
                    <div className="card-text">
                      <div className="card-para">
                        <div className='logoCompany'>
                          <img src={require('../src/assets/project2/airport.png')} alt="Microsoft Logo" className="microsoft-logo atlogo" />
                          <h3 className="microsoft-title">Hartsfield-Jackson Atlanta International Airport</h3>
                        </div>

                        {/* <h3>EdCuration</h3> */}
                        <div className="tags">
                          <p>UX Research</p>
                          <p>Product Design</p>
                          <p>Accessibility</p>
                        </div>
                        <p>Optimizing airport experiences for individuals with reduced mobility.</p>
                      </div>

                      <Button variant="contained" className="see-project-button" onClick={() => window.open('/atl-airport', '_blank')}>
                        Learn more
                      </Button>
                      {/* <Button onClick={() => navigate('/project')} variant="contained" className="see-project-button">
                        See Project
                      </Button> */}

                    </div>
                    <div className="card-image mockupmicro">
                      <img src={require('../src/assets/project2/mockupatl.png')} alt={`Card`} />
                    </div>
                  </div>
                </div>

                {/* EdCuration */}
                {/* <div className=" cards-container ">
                  <div className={`card ${inView1 ? 'animate__animated animate__flipInX' : ''}`} ref={ref1}>
                    <div className="card-text">
                      <div className="card-para">
                        <img src={require('../src/assets/project1/ed.png')} alt="Microsoft Logo" className="microsoft-logo" />
                        <h3 className="microsoft-title">EdCuration</h3>
                        <p>Redesigning an online marketplace to optimize vendor experience and streamline the listing of educational products.</p>
                      </div>

                      <Button variant="contained" className="see-project-button" onClick={() => window.open("https://aelaser.github.io/oldwebsite/portfolio-edcuration.html", '_blank')}>
                        Learn more
                      </Button>
                     

                    </div>
                    <div className="card-image edcuration">
                      <img src="https://i.postimg.cc/SRK9Rt3h/edcuration-40-prototype.png" alt={`Card`} />
                    </div>
                  </div>
                </div> */}

                {/* <div className="cards-container">
                  <div className={`card ${inView2 ? 'animate__animated animate__flipInX' : ''}`} ref={ref2}>

                    <div className="card-text">
                      <div className="card-para">
                        <img src={require('../src/assets/project1/jam.png')} alt="Microsoft Logo" className="microsoft-logo" />
                        <h3 className="microsoft-title">Jam Pan</h3>

                        <p>Designing an online platform to connect businesses with e-Learning suppliers to expand the industry's network of clients.</p>
                      </div>
                      <Button variant="contained" className="see-project-button" onClick={() => window.open("https://aelaser.github.io/oldwebsite/portfolio-ux-design.html", '_blank')}>Learn more</Button> 
                    </div>
                    <div className="card-image jampan">
                      <img src="https://i.postimg.cc/k5sCpV6K/jampan.png" alt={`Card`} />
                    </div>
                  </div>
                </div> */}

                <div className="cards-container">
                  <div className={`card ${inView3 ? 'animate__animated animate__flipInX' : ''}`} ref={ref3}>

                    <div className="card-text">
                      <div className="card-para">
                        <h3>Freestyle</h3>
                        <div className="tags">
                          <p>Wearable Device</p>
                          <p>UX Design</p>
                          <p>Rapid Prototyping</p>
                        </div>
                        <p>Helping swimmers track and analyze swimming sessions to improve performance.</p>
                      </div>

                      <Button variant="contained" className="see-project-button" onClick={() => window.open("https://www.figma.com/proto/m4ZK1kLAYGaEqPi39UYpCy/Untitled?type=design&node-id=1-1799&scaling=contain&page-id=1%3A1088&starting-point-node-id=1%3A1799&mode=design&t=euRQzFTBxaojtqiy-1", '_blank')}>Learn more</Button> {/* Add this line */}
                    </div>
                    <div className="card-image freestyle">
                      <img src="https://i.postimg.cc/RVsY37rW/Screens.png" alt={`Card`} />
                    </div>
                  </div>
                </div>





              </>
            </div>
            <div className={active === 'play' ? 'section-active' : 'section-hidden'}>
              <>
                {/* Play Page Content */}
                <div style={{ textAlign: 'center' }}>
                  <Box component="h1" className="animate__animated animate__fadeIn subtitle-left subtitle-left play-title">
                    And I enjoy coding on the side...
                  </Box>
                  <Box component="h2" className="animate__animated animate__fadeInDown subtitle-smaller">
                    Take the UFO through the maze to the planet<FontAwesomeIcon icon={faAngleDown} className="bouncing-icon" />
                  </Box>
                </div>



                <div className="animate__animated animate__bounceIn animate__delay-0.8s RiveContainer Minigame">
                  <Rive
                    src={logo}
                    stateMachines="State Machine 1"
                    autoplay='true'
                    style={{ width: '100%', height: '100%', transform: 'translateY(-20px)' }} /* Adjust as needed */
                  />
                </div>
                <Box component="h1" className={`case-studies coding-project ${inView9 ? 'animate__animated animate__fadeIn animate__delay-0.8s' : ''}`} ref={ref9}> Coding Projects</Box>
                <div className="cards-container">
                  <div className={`card ${inView4 ? 'animate__animated animate__flipInX' : ''}`} ref={ref4}>
                    <div className="card-text">
                      <div className="card-para">
                        <h3>
                          Emora Chat / <span style={{ fontSize: "15px" }}>Flutter</span></h3>
                        <p>Emora Chat presents an AI-powered chatbot that helps students achieve their academic goals and career success.</p>
                      </div>
                      <Button variant="contained" className="see-project-button" onClick={() => window.open("https://apps.apple.com/us/app/emora-chat/id1638974060", '_blank')} >See Project</Button> {/* Add this line */}
                    </div>
                    <div className="card-image">
                      <img src="https://i.postimg.cc/gJLmNrX7/Dribbble-shot-HD-03.png" alt={`Card`} />
                    </div>
                  </div>
                </div>
                <div className="cards-container">
                  <div className={`card ${inView5 ? 'animate__animated animate__flipInX' : ''}`} ref={ref5}>
                    <div className="card-text">
                      <div className="card-para">
                        <h3>
                          Interactivity@GT / <span style={{ fontSize: "15px" }}>React</span></h3>
                        <p>Interactivity@GT is the annual research showcase featuring students and faculty engaged in people-centric innovation at Georgia Tech.</p>
                      </div>
                      <Button variant="contained" className="see-project-button" onClick={() => window.open("https://interactivity-gt.vercel.app", '_blank')}>See Project</Button> {/* Add this line */}
                    </div>
                    <div className="card-image laptop">
                      <img src="https://i.postimg.cc/8PzZR7Dx/Macbook-2.png" alt={`Card`} />
                    </div>
                  </div>
                </div>
                <div className="cards-container">
                  <div className={`card ${inView6 ? 'animate__animated animate__flipInX' : ''}`} ref={ref6}>
                    <div className="card-text">
                      <div className="card-para">
                        <h3>
                          Trini / <span style={{ fontSize: "15px" }}>Flutter</span> </h3>
                        <p>Trini, an app designed to help powerlifters track their workout progress and personal records (PRs).</p>
                      </div>
                      <Button variant="contained" className="see-project-button" onClick={() => window.open("https://github.com/aelaser/trini.git", '_blank')}>See Project</Button> {/* Add this line */}
                    </div>
                    <div className="card-image">
                      <img src="https://i.postimg.cc/WzL7q7TP/trini.png" alt={`Card`} />
                    </div>
                  </div>
                </div>

                <div className="cards-container">
                  <div className={`card ${inView7 ? 'animate__animated animate__flipInX' : ''}`} ref={ref7}>
                    <div className="card-text">
                      <div className="card-para">
                        <h3>
                          Hulu / <span style={{ fontSize: "15px" }}>NextJS</span> </h3>
                        <p>A reimagination and implementation of Hulu's interface for an enhanced user experience</p>
                      </div>
                      <Button variant="contained" className="see-project-button" onClick={() => window.open("https://streaming-service-drab.vercel.app", '_blank')}>See Project</Button> {/* Add this line */}
                    </div>
                    <div className="card-image laptop">
                      <img src="https://i.postimg.cc/nzqPPgw5/Macbook.png" alt={`Card`} />
                    </div>
                  </div>
                </div>
              </>
            </div>


          </Box>
          {/* Preload Placeholder */}
        </Container>
      </Box >

    </ThemeProvider >
  );
}

export default LandingPage;
